.collection-cards-main-wrapper {
  width: 100%;
}

.SetData-Btn {
  display: flex;
  // margin-top: 15px;
  justify-content: flex-end;
  align-items: center;
  // width: 738px;
  height: 40px;
  padding: 10px 0px 6px 0px;
  @media screen and (max-width: 576px) {
    justify-content: center;

  }

  .active {
    background-color: blue;
    color: white;
  }

  button {
    // border: 1px solid lightgray;
    border: none !important;
    color: #303030;
    // border-radius: 4px;
    background-color: white !important;
    img, svg{
      color: #000000;
      filter: brightness(0) saturate(100%) invert(0%) sepia(90%) saturate(7405%) hue-rotate(198deg) brightness(128%) contrast(101%);
    }
    // padding: 4px 0px 4px 0px;
    cursor: pointer;
    font-size: 22px;
    letter-spacing: 0.0178571429em;
    font-weight: 500;
    margin-left: 7px;
    width: 25px;
    height: 40px;
    gap: 4px;
    text-transform: none;
    padding-inline: 0;
    @media screen and (max-width: 576px) {
      font-size: 16px;
      width: 20px;

    }
  }
}

.span-14 {
  // border-radius: 4px;
  // width: 65px;
  font-size: 14px;
  font-weight: 400;
  gap: 4px;
  align-items: center;
  font-style: normal;
  line-height: 20px;
  background-color: #fff;
  display: flex;
  padding: 0px 0;
  @media screen and (max-width: 576px) {
    font-size: 10px;

  }
}
.span-15{
  // style={{marginTop:'15px', fontSize:'14px' , fontWeight:'400' , marginLeft:'25px' , marginRight:'20px'}}
  font-size: 14px;
  font-weight: 400;
  gap: 4px;
  font-style: normal;
  line-height: 20px;
  background-color: #fff;
  display: flex;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 15px;
  @media screen and (max-width: 576px) {
    font-size: 12px;
    margin: 0px 20px 0px;

  }
}

.div-39 {
  color: var(--Primary-text, #2d2f31);
  /* text-align: center; */
  // padding: 6px 8px 6px 8px;
  // flex-grow: 1;
  font-size: 14px;
  font-weight: 600;
  // width: 65px;
  border: none;
  height: 40px;
  line-height: 20px;
  gap: 4px;
  white-space: nowrap;
  // border: 1px solid lightgray;
  background-color: #fff;
  // border-radius: 4px;
  // font: 400 14px/143% Poppins, sans-serif;
  // border: none;
  @media screen and (max-width: 576px) {
    font-size: 12px;

  }
}

@media (max-width: 991px) {
  .div-39 {
    white-space: initial;
  }
}
