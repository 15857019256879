@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://site-assets.fontawesome.com/releases/v6.4.0/css/all.css");
$main: #2181fa;
$border-light: #cccccc;
$primary: #4286f5;
$white: #fff;
$bg-light: #fbfbfb;
$lightgray: #f0f0f0;
$black: #202223;
$box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
$border: #8c9196;
$border-light: #cccccc;

h1,
h2,
h3,
h4,
h5,
h6,
b,
small {
  font-family: Poppins !important;
}

p,
span,
a,
.nav-link,
.nav-item,
.navbar-nav,
input {
  font-family: Poppins !important;
}

input,
textarea,
button,
.btn {
  outline: 2px solid transparent !important;
  box-shadow: none !important;
}

.lg-container {
  max-width: 1400px;
  margin: auto;
}

body {
  font-family: Poppins !important;
  overflow-x: hidden;
}

.animatedup {
  animation: up-down 1.5s ease-in-out infinite alternate-reverse both;
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

.animatedleft {
  animation: left-right 1s ease-in-out infinite alternate-reverse both;
}

@keyframes left-right {
  0% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(-20px);
  }
}

.container-fluid {
  overflow-x: hidden;
  padding: 0px !important;
}

.container-full {
  padding: 0px 70px;
  width: 100%;
}

.dropdown-toggle::after {
  margin-left: 0.255em;
  vertical-align: 0.255em;
  display: none !important;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.auth-header {
  background: white;
  border-bottom: 1px solid #cccccc;
  padding: 0px 30px;
  width: 100%;
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;

  .bredcrumbb {
    font-weight: 600;
    padding: 0px 20px;
  }

  .logo {
    img {
      height: 32px;
    }
  }

  .left-nav {
    display: flex;
    align-items: center;
    width: calc(100% - 32px);

    a {
      text-decoration: none;
    }

    .nav-link {
      color: black;
      font-size: 14px;
      font-weight: 400;
      height: 60px;
      padding-top: 2px;
      display: flex;
      align-items: center;
      padding: 0px 10px;

      i {
        margin-right: 8px;
      }
    }

    .nav-link.active {
      font-weight: 600;
      color: $main;
    }
  }

  .right-nav {
    width: 32px;

    .dropdown {
      a {
        border-radius: 5px;
        color: black;
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .dropdown .btn {
      padding: 0px;
      background: transparent;
      border: none;
    }

    .dropdown-toggle::after {
      display: none;
    }

    display: flex;
    align-items: center;

    .nav-link {
      font-size: 16px;
      color: $main;
      font-weight: 600;
      margin-right: 20px;
      cursor: pointer;

      svg {
        height: 24px;
        width: 24px;
        color: #202223;
      }
    }

    .user-profile {
      height: 40px;
      width: 40px;
      border-radius: 100px;

      img {
        height: 40px;
        width: 40px;
        border-radius: 100%;
      }
    }
  }
}

.header-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.inbox-notification {
  position: relative;

  .badge {
    position: absolute;
    right: -4px;
    top: -4px;
    height: 15px;
    border-radius: 100px;
    padding: 0px;
    width: 15px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.menu-icon {
  margin-right: 15px;
  .dropdown-toggle {
    height: 36px;
    width: 36px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all ease-in-out 0.4s;
    @media only screen and (max-width: 767px) {
      height: 30px;
      width: 30px;
    }
    &:active {
      color: #1c1b1f;
    }
  }
  svg {
    height: 24px;
    width: 24px;
    object-fit: contain;
    color: #1c1b1f;
    @media only screen and (max-width: 767px) {
      height: 18px;
      width: 18px;
    }
  }
}

.menu-icon:hover {
  background: #f5f5f5;
}

.search-bar {
  display: flex;
  align-items: center;

  i {
    position: absolute;
    top: 10px;
  }

  .react-datalist-input__container input {
    height: 32px !important;
    border: 1px solid #8c9196 !important;
    border-radius: 109px;
    font-size: 12px;
    padding: 0px 10px;
  }

  .fa-magnifying-glass {
    top: 9px;
    font-size: 14px;
    right: 10px;
    position: absolute;
  }
}

.user-drop-down {
  .dropdown-menu {
    border-radius: 4px;
    padding: 15px 0px;
    width: 240px;
    margin-top: 9px;
  }

  .user-info {
    max-width: 100%;
    padding: 0px 15px;

    h6 {
      text-transform: capitalize;
      margin-bottom: 5px;
      color: $main;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: -0.02em;
    }

    p {
      color: black;
      text-decoration: none;
      font-size: 14px;
      margin-bottom: 10px;
      font-weight: 500;
    }
  }
}

@media only screen and (max-width: 768px) {
  .auth-header {
    padding: 5px 15px !important;
  }

  .auth-header .left-nav .nav-link {
    padding: 0px 5px;
  }
  .auth-header .left-nav .nav-link {
    height: 50px;
    font-size: 12px !important;
  }

  .bredcrumbb {
    font-size: 13px;
    padding: 0px 10px !important;
  }

  .left-nav .bredcrumbb {
    display: none !important;
  }

  .auth-header .right-nav .nav-link {
    margin-right: 10px;

    svg {
      height: 22px;
      width: 22px;
    }
  }

  .user-profile {
    height: 32px !important;
    width: 32px !important;

    img {
      height: 32px !important;
      width: 32px !important;
    }
  }
}

.search-bar {
  margin-right: -8px;
  height: 32px;
  position: relative;

  input {
    top: 1px;
    bottom: 0;
    right: 0;
    position: absolute;
    transform: translateY(20px);
    animation: popUp 0.5s forwards;
  }

  svg {
    position: absolute;
    right: 5px;
    top: 5px;
    bottom: 5px;
  }
}

@keyframes popUp {
  from {
    opacity: 0;
    transform: scale(0.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.header-top-nav {
  margin: 0px 10px;
  display: flex;
  overflow: auto;
}
