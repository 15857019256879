@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

$poppins: Poppins;

$black: #000000;
$primary01: #202223;
$primary02: #2d2f31;
$blue: #2181fa;
$white: #fff;
$gray: #dee1e6;
$border: #cccccc;
$main: #4286f5;

h2 {
  font-family: $poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
a {
  text-decoration: none !important;
  font-family: $poppins;
}
p {
  font-family: $poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
strong {
  font-family: $poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
span {
  font-family: $poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &.span {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
ul {
  margin: 0 0 0 0 !important;
  padding: 0 0 0 0 !important;
  li {
    list-style-type: none;
    font-family: $poppins;
    display: inline-block;
  }
}

//**************************** Global scss Start ***********************************

header.analytics-header {
  padding: 12px 20px;
  margin: auto;
  border-bottom: 1px solid #dee1e6;
  height: 64px;
  @media screen and (max-width: 991px) and (min-width: 768px) {
    padding: 8px 30px;
  }
  @media screen and (max-width: 767px) {
    padding: 8px 20px;
  }
  > .container-fluid {
    overflow: visible !important;
  }
  a#hamburger {
    display: none;
    @media screen and (max-width: 991px) {
      display: block;
    }
    img,
    svg {
      width: 24px;
      height: 24px;
      @media screen and (max-width: 991px) and (min-width: 768px) {
        width: 30px;
        height: 30px;
      }
    }
  }
  .logo-wrapper {
    position: relative;
    @media screen and (max-width: 991px) and (min-width: 768px) {
      gap: 25px !important;
    }
    @media screen and (max-width: 991px) {
      display: flex;
      align-items: center;
      gap: 15px;
    }
 
    a {
      display: inline-flex;
      align-items: center;
      gap: 15px;
      @media screen and (max-width: 767px) {
        gap: 10px;
      }
      img {
        width: 36px;
        height: 36px;
        border-radius: 4px;

        @media screen and (max-width: 767px) {
          width: 24px;
          height: 24px;
        }
      }
      strong {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.56px;
        color: #2d2f31;
      }
    }

    .dropdown-div {
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
      padding: 20px 20px 20px 20px;
      position: absolute;
      left: 0;
      top: 50px;
      z-index: 99;
      display: none;
      width: 240px;
      //   width: 100%;
      .title {
        margin: 0 0 8px;
      }
        &.active {
            display: block;
        }
      ul.list {
        padding: 10px 0 10px 0 !important;
        border-top: 1px solid $gray;
        li {
          margin: 0 0 0 0 !important;
          display: block;
          &:not(&:last-of-type) {
            margin: 0 0 14px !important;
          }
          a {
            display: flex;
            align-items: center;
            gap: 12px;
            color: black;
            &.active,
            &:hover {
              color: $blue;
              svg {
                color: $blue;
              }
            }
          }
          span.more-icon {
            display: block;
            width: 100%;
            position: relative;
            img,
            svg {
              float: right;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
  .header-menu {
    text-align: right;
    ul.icons-wrapper {
      margin-bottom: 0;
      padding-left: 0;
      > li {
        position: relative;
        display: inline-block;
        vertical-align: middle;
          > a {
            &:hover, &.active{
              color: $blue;
              > svg {
                color: $blue;
              }
            }
          }
        &:not(&:last-of-type) {
          margin: 0 32px 0 0;
          @media screen and (max-width: 767px) {
            margin: 0 10px 0 0;
          }
          &:after {
            content: "";
            display: block;
            position: absolute;
            right: -17px;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            background: #dee1e6;
            height: 36px;
            @media screen and (max-width: 767px) {
              right: -7px;
            }
          }
        }
        a {
          display: inline-block;
          vertical-align: middle;
          img,
          svg,
          i {
            width: 20px;
            height: 20px;
            color: $black;
            // fill: $black;
            @media screen and (max-width: 767px) {
              width: 22px;
              height: 22px;
            }
          }
        }
        .dropdown-div {
          border-radius: 8px;
          background: #fff;
          box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
          padding: 20px;
          position: absolute;
          right: 0;
          top: 48px;
          z-index: 99;
          display: none;
          min-width: 230px;
          &.active {
            display: block;
        }
          &.profile {
            padding: 24px;
            top: 46px;
      
          }
          .img-wrapper {
            text-align: center;
            margin: 0 0 24px 0;
            img {
              width: 110px;
              height: 110px;
              object-fit: cover;
              border-radius: 50%;
            }
          }
          .content-wrapper {
            text-align: center;
            span.span {
              a {
                color: $blue;
              }
            }
            p.name {
              font-size: 14px;
            }
            span {
              font-size: 12px;
            }
            ul.list {
              text-align: left;
              margin: 28px 0 0 0 !important;
              li {
                display: block;
                margin: 0 0 0 0 !important;
                &:not(&:last-of-type) {
                  margin: 0 0 20px 0 !important;
                }
                a {
                  display: flex;
                  gap: 8px;
                  color: black;
                  svg {
                    width: 15px;
                    height: 15px;
                  }
                  &.online {
                    svg {
                      fill: #00ab1b;
                      color: #00ab1b;
                    }
                  }
                  .text {
                    display: flex;
                    gap: 8px;
                  }
                }
              }
            }
          }
          ul.page-list {
            li {
              margin: 0 0 0 0 !important;
              display: block;
              &:not(&:last-of-type) {
                margin: 0 0 14px !important;
              }
              a {
                display: flex;
                align-items: center;
                gap: 12px;
                color: black;
                &.active,
                &:hover {
                  color: $blue;
                  svg {
                    color: $blue;
                  }
                }
              }
            }
          }
        }
        ul.icon-list {
          vertical-align: middle;
          padding-left: 0;
          li {
            vertical-align: middle;
            display: inline-block;
            list-style-type: none;
            position: relative;
              > a {
                &:hover, &.active{
                  color: $blue;
                  > svg {
                    color: $blue;
                  }
                }
              
              }
            &:not(&:first-of-type) {
              margin: 0 0 0 16px;
              @media screen and (max-width: 767px) {
                margin: 0 0 0 8px;
              }
            }
            &.avatar {
              margin: 0 0 0 10px;
              position: relative;
              &:after {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                background: #00ab1b;
                position: absolute;
                top: 1px;
                right: 0;
                border-radius: 50%;
              }
              img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                @media screen and (max-width: 767px) {
                  width: 35px;
                  height: 35px;
                }
              }
            }
            .dropdown-div {
              border-radius: 8px;
              background: #fff;
              box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
              padding: 20px;
              position: absolute;
              right: 0;
              top: 30px;
              z-index: 99;
              display: none;
              min-width: 230px;
              &.active{
                display: block;
              }
              &.profile {
                padding: 24px;
               top: 60px;
                min-width: 240px;
              }
              .img-wrapper {
                text-align: center;
                margin: 0 0 24px 0;
                img {
                  width: 110px;
                  height: 110px;
                  object-fit: cover;
                  border-radius: 50%;
                }
              }
              .content-wrapper {
                text-align: center;
                span.span {
                  a {
                    color: $blue;
                  }
                }
                p.name {
                  font-size: 14px;
                }
                span {
                  font-size: 12px;
                }
                ul.list {
                  text-align: left;
                  margin: 10px 0 0 0 !important;
                  border-top: 1px solid #DEE1E6;
                  padding: 15px 0 0 0 !important;
                  li {
                    display: block;
                    margin: 0 0 0 0 !important;
                    &:not(&:last-of-type) {
                      margin: 0 0 20px 0 !important;
                    }
                    a {
                      display: flex;
                      gap: 8px;
                      color: black;
                      svg {
                        width: 15px;
                        height: 15px;
                      }
                      &.online {
                        svg {
                          fill: #00ab1b;
                          color: #00ab1b;
                        }
                      }
                      .text {
                        display: flex;
                        gap: 8px;
                      }
                    }
                  }
                }
              }
              ul.page-list {
                li {
                  margin: 0 0 0 0 !important;
                  display: block;
                  &:not(&:last-of-type) {
                    margin: 0 0 14px !important;
                  }
                  a {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    color: black;
                    &.active,
                    &:hover {
                      color: $blue;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.icon-wrapper {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  img,
  svg {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
}
#side-bar-wrapper {
  max-width: 164px;
  width: 100%;
  height: calc(100vh - 70px);
  border-right: 1px solid $gray;
  transition: 0.4s ease-in-out all;
  position: absolute;
  top: 0;
  left: 0;
  @media screen and (max-width: 991px) {
    position: fixed;
    max-width: 50%;
    height: 100vh;
    left: -100%;
    z-index: 99;
    background: white;
    transition: 0.4s ease-in-out all;
  }
  &.show {
    left: 0;
    transition: 0.4s ease-in-out all;
  }
  &.active {
    max-width: 70px;
    transition: 0.4s ease-in-out all;
    strong {
      visibility: hidden !important;
      opacity: 0 !important;
      width: 0 !important;
      transition: 0.3s ease-in-out all;
    }
    span {
      visibility: hidden !important;
      opacity: 0 !important;
      width: 0 !important;
      transition: 0.3s ease-in-out all;
    }
    .close-icon-wrapper {
      a {
        img {
          filter: grayscale(100%) !important;
          transform: rotate(180deg);
          transition: 0.4s ease-in-out all;
        }
      }
    }
  }
  .side-bar-title-wrapper {
    border-bottom: 1px solid #dee1e6;
    padding: 7px 15px 8px 16px;
    a {
      display: inline-flex;
      align-items: center;
      gap: 10px;
      padding: 0 10px;
      min-height: 44px;
      img {
        width: 18px;
        height: 18px;
        object-fit: contain;
        border-radius: 3px;
      }
      strong {
        color: $primary02;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        visibility: visible;
        opacity: 1;
        width: auto;
        transition: 0.4s ease-in-out all;
      }
    }
  }
  .sidebar-menu-wrapper {
    padding: 0 20px 0 17px;
    margin: 10px 0 10px 0;
    height: calc(100vh - 220px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $blue;
    }
    ul.sidebar-menu {
      height: 100%;
      li {
        display: block;
        &:not(&:last-of-type) {
          margin: 0 0 5px;
        }
        a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          padding: 10px;
          &.active,
          &:hover {
            color: $blue;
            span {
              color: $blue;
            }
            i,
            svg,
            img {
              color: $blue;
            }
          }
          img,
          svg,
          i {
            width: 13px;
            height: 13px;
            min-width: 13px;
            object-fit: contain;
            color: black;
            // fill: black;
          }
          span {
            color: $primary02;
            visibility: visible;
            opacity: 1;
            width: auto;
            transition: 0.4s ease-in-out all;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .close-icon-wrapper {
    text-align: right;
    padding: 20px 21px 21px;
    border-top: 1px solid $gray;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    a.sidebar-toggle-close {
      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        filter: none;
      }
    }
  }
}
#overLay {
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  z-index: 3;
  opacity: 0;
  transition: 0.6s ease-in-out all;
  &.show {
    visibility: visible;
    transition: 0.6s ease-in-out all;
    opacity: 1;
  }
}
#main-page-body-wrapper {
  margin: 0 0 0 164px;
  transition: 0.4s ease-in-out all;
  height: calc(100vh - 90px);
  overflow-y: scroll;
  @media screen and (max-width: 991px) {
    margin: 0 0 0 0;
  }
  &.active {
    margin: 0 0 0 70px;
    transition: 0.4s ease-in-out all;
    @media screen and (max-width: 991px) {
      margin: 0 0 0 0;
    }
  }
}
.breadcrumbs-topbar {
  padding: 8px 0 7px 20px;
  border-bottom: 1px solid $gray;
  .bread-crumbs-wrapper {
    img.open-screen-icon {
      width: 20px;
      height: 20px;
      object-fit: contain;
      margin: 0 10px 0 0;
    }
    ul.menu {
      display: inline-block;
      li {
        &:not(&:first-of-type) {
          margin: 0 0 0 10px;
          a {
            &:before {
              content: ">";
              color: black;
              font-size: 18px;
              position: absolute;
              top: 50%;
              left: -10px;
              transform: translateY(-50%);
            }
          }
        }
        a {
          color: #2d2f31;
          padding: 10px;
          font-weight: bold;
          position: relative;
          display: block;
          &.active {
            color: $blue;
          }
        }
      }
    }
  }
}

//**************************** Global scss End *************************************

.main-dashboard-wrapper {
  position: relative;
}

header{
  .dropdown-toggle {
    padding: 0px;
    border-radius: 100px;
    border: none;
    height: 40px;
    >img, svg{
      color: black;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu {
    position: absolute !important;
  top: 50px !important;
    padding: 24px;
    width: 210px;
    border-radius: 8px;
    background: #fff;
    border: none;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
    z-index: 999;
    transform: none !important;
    inset: 52px auto auto auto !important;
    right: 0 !important;
    padding: 10px 15px;
    right: 0px !important;
    .user-profile {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-bottom: 1px solid $border;
      margin-bottom: 10px;

      .profile {
        border-radius: 100px;
        // border: 1px solid var(--fade-black10, rgba(27, 31, 35, 0.1));
        width: 110px;
        height: 110px;
        margin-bottom: 15px;
        position: relative;

        img {
          height: 100%;
          border-radius: 100px;
          width: 100%;
          object-fit: cover;
        }

        .status {
          position: absolute;
          width: 20px;
          height: 20px;
          flex-shrink: 0;
          border-radius: 100px;
          background: $border;
          border: 1px solid white;
          bottom: 5px;
          right: 5px;
          z-index: 99999999;
        }

        .status.active {
          background: #22c55e;
        }
      }

      h3 {
        margin-bottom: 15px;
        text-align: center;
        color: var(--Primary-text, #2d2f31);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        text-transform: uppercase;
      }

      h6 {
        color: #2181fa;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 15px;
      }
    }

    .options {
      .option {
        padding: 10px 0px;
        display: flex;
        cursor: pointer;

        svg {
          margin-right: 8px;
        }

        span {
          color: var(--Primary-text, #2d2f31);
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          /* 150% */
        }
      }

      .status {
        padding: 8px 0px;
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;

        .stat {
          display: flex;
          align-items: center;
        }

        .dot {
          margin-right: 8px;
          width: 15px;
          height: 15px;
          background: #ef4444;
          border-radius: 100%;
        }

        .dot.active {
          background: #22c55e;
        }

        span {
          color: var(--Primary-text, #2d2f31);
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          /* 150% */
        }
      }

      .option:hover {
        span {
          color: $main;
        }

        svg path {
          fill: $main !important;
        }
      }

      .option:last-child {
        padding-bottom: 0px;
        border-top: 1px solid $border;
      }
    }
  }
  .portal {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 5px;
    .icon{
      width: 36px;
      height: 36px;
      flex-shrink: 0;
      border-radius: 4px;
      background: white !important;
      margin-right: 12px;
      filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
      display: flex;
      align-items: center;
      justify-content: center;
      img, svg{
        width: 20px !important;
        height: 20px !important;
        margin: 0 0 0 0;
        filter: none;
      }
     
    }
    svg, img {
      width: 36px !important;
      height: 36px !important;
      flex-shrink: 0;
      border-radius: 4px;
      background: white !important;
      margin-right: 12px;
      filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
    }

    span {
      color: var(--Primary-text, #2d2f31);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 0px;
      margin-left: 8px;
      margin-left: 8px;
    }
  }

  .portal:hover {
    span {
      color: $main;
    }
  }
}