@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://site-assets.fontawesome.com/releases/v6.4.0/css/all.css");
$main: #2181fa;
$border-light: #cccccc;
$primary: #4286f5;
$white: #fff;
$bg-light: #fbfbfb;
$lightgray: #f0f0f0;
$black: #202223;
$box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
$border: #8c9196;
$border-light: #cccccc;
$card-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
$border: #cccccc;

h1,
h2,
h3,
h4,
h5,
h6,
b,
small {
  font-family: Poppins !important;
}

p,
span,
a,
.nav-link,
.nav-item,
.navbar-nav,
input {
  font-family: Poppins !important;
}

input,
textarea,
button,
.btn {
  outline: 2px solid transparent !important;
  box-shadow: none !important;
}

html {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $main;
}

html,
body {
  transition: all ease-in-out 0.4s;
  scroll-behavior: smooth;
}

.lg-container {
  // padding: 0px 100px !important;
  max-width: 1400px;
  margin: auto;
}

body {
  font-family: Poppins !important;
  overflow-x: hidden;
}

.animatedup {
  animation: up-down 1.5s ease-in-out infinite alternate-reverse both;
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

.animatedleft {
  animation: left-right 1s ease-in-out infinite alternate-reverse both;
}

@keyframes left-right {
  0% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(-20px);
  }
}

.container-fluid {
  overflow-x: hidden;
  padding: 0px !important;
}

.container-full {
  padding: 0px 70px;
  width: 100%;
}

.note {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 600;
}

.auth-footer {
  width: 100%;
  height: 60px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fbfbfb;
  display: flex;
  align-items: center;
  justify-content: center;

  .footer-body {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .nav-link {
      color: black;
      font-size: 14px;
    }
  }
}

.cdb-side-bar .sidebar-content .item-content :hover {
  color: #2181fa;
}

.auth-body {
  width: 100%;
  flex-direction: column;
  min-height: calc(100vh - 140px);
  display: flex;
  justify-content: center;
  align-items: center;

  .auth-form {
    .form-check-label {
      font-size: 14px;
    }

    .diff-btn {
      padding: 10px 0px;
      border-radius: 0px;
      font-size: 14px;
      letter-spacing: 0.4px;
      border-bottom: 1px solid #cccccc;
    }

    .mail-img {
      height: 128px;
      margin-bottom: 20px;
    }

    width: 562px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #ffffff;
    /* Shadow (SM) */
    padding: 50px 70px 35px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .fav {
      height: 100px;
      margin-bottom: 15px;
    }

    .signup-btns {
      width: 420px;
      padding: 10px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn-outline {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        width: 100%;
        margin-bottom: 16px;
        height: 48px;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 7px;

        img {
          width: 15px;
          margin-right: 10px;
        }
      }
    }

    small {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 10px;
      display: flex;
      align-items: flex-end;
      text-align: center;
      color: #3d3d3d;
    }

    h2 {
      font-weight: 600;
      font-size: 33px;
      margin-bottom: 25px;
      display: flex;
      align-items: flex-end;
      text-align: center;
      letter-spacing: -0.03em;
      color: #202223;
    }

    h6 {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 40px;
      display: flex;
      align-items: flex-end;
      text-align: center;
      color: #3d3d3d;

      a {
        margin-left: 5px;
        color: $main;
        text-decoration: none;
      }
    }

    small {
      font-weight: 500;
      font-size: 14px;
      display: flex;
      align-items: flex-end;
      text-align: center;
      color: #3d3d3d;

      a {
        margin-left: 5px;
        color: $main;
        text-decoration: none;
      }
    }

    .field-row {
      text-align: left;
      width: 422px;

      .form-label {
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: flex-end;
        color: #202223;
      }

      input {
        width: 100%;
        border: 1px solid #cccccc;
        border-radius: 7px;
        height: 50px;
      }

      select {
        border: 1px solid #cccccc;
        border-radius: 7px;
        height: 50px;
      }

      .btn {
        width: 100%;
        height: 48px;
        border: none;
        background: #2181fa;
        border-radius: 7px;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.03em;
      }
    }
  }

  h5 {
    font-weight: 500;
    font-size: 30px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 30px;
  }

  .devider {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 0px 30px;

    .xyz {
      width: 495px;
      height: 1px;
      background-color: #d9d9d9;
    }

    .or {
      margin-top: -15px;
      padding: 0px 10px;
      background-color: white;
      font-size: 18px;
      color: #3d3d3d;
    }
  }

  .network-url {
    display: flex;
    width: 422px;
    margin-bottom: 25px;
    justify-content: space-between;

    input {
      width: calc(100% - 70px);
      height: 50px;
      border: 1px solid #cccccc;
      border-radius: 7px;
    }

    .btn {
      width: 50px;
      height: 50px;
      background: #f1f1f1;
      border-radius: 6px;

      i {
        color: #0000008a;
      }
    }
  }
}

.go2392553372 {
  padding-left: 0px !important;
  max-width: 562px;
}

.go2150698616:before {
  left: 27% !important;
}

.go2392553372 li::before {
  height: 40px !important;
  width: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  bottom: -100px !important;
}

.go433304200:before {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
  background-color: #33c3f0 !important;
}

.go3842770039:before {
  content: "o" !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
  background-color: #33c3f0 !important;
}

.image-uploader {
  height: 110px;
  width: 110px;
  background: #f0f0f0;
  border-radius: 100px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  line-height: 14px;
  flex-direction: column;

  small {
    color: #8c9196;
    font-size: 12px !important;
    font-weight: 600;
  }

  i {
    margin-top: 20px;
    color: #8c9196;
    margin-bottom: 5px;
    font-size: 18px;
  }
}

.StepperContainer-0-2-1 {
  width: 562px;
  padding: 20px 0px !important;

  button.active {
    background: $main !important;
  }

  button.completed {
    background: $main !important;
  }
}

.loader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100px;
  }
}

.no-data-found {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 230px);

  img {
    height: 200px;
    border-radius: 15px;
    object-fit: cover;
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
  }
}

.dashboard-body {
  padding: 73px 0 0px;

  .body-content {
    max-width: 1100px;
    margin: auto;

    .col-lg-4.col-md-6 {
      margin-bottom: 30px;
    }

    h1 {
      font-weight: 600;
      font-size: 33px;
      margin-bottom: 20px;
      letter-spacing: -0.02em;
      color: #000000;
    }

    h5 {
      font-weight: 600;
      font-size: 16px;
      letter-spacing: -0.02em;
      margin-bottom: 30px;
      color: #202223;
    }

    a {
      text-decoration: none;
      color: $main;
    }

    h6 {
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 30px;
      color: #202223;

      span {
        font-weight: 500;
      }
    }

    h3 {
      font-weight: 600;
      font-size: 23px;
      letter-spacing: -0.02em;
      color: #000000;
      margin-bottom: 30px;
    }

    .add-btns {
      display: flex;
      margin-bottom: 50px;

      .btn {
        margin-right: 20px;
        width: 196px;
        height: 40px;
        background: #ffffff;
        border: 2px solid #2181fa;
        border-radius: 8px;
        font-size: 16px;
        text-align: center;
        color: #2181fa;

        i {
          margin-right: 6px;
          font-size: 13px;
        }
      }
    }
  }
}

.ITALIC {
  font-style: normal !important;
}

.admin-body {
  padding: 40px 120px;
}

.collection-outer-body {
  padding: 20px 15px;

  .pagination {
    justify-content: center;
  }

  .collection-grid-card {
    .dropdown {
      button {
        background-color: transparent !important;
        color: black;
        border: none;
      }

      button::after {
        display: none !important;
      }
    }
  }
}

.cole-book-list-card {
  transition: all ease-in-out 0.2s;
  min-height: 100px;
  width: 100%;
  background-color: white;
  border: 1px solid $border-light !important;
  border-radius: 6px;
  padding: 15px 25px;
  display: flex;
  cursor: pointer;
  margin-bottom: 20px;
  justify-content: space-evenly;
  align-items: center;

  .book-detail:nth-child(1) {
    width: 30% !important;
  }

  // .book-detail:nth-child(2) {
  //   width: 170% !important;
  // }

  .book-detail {
    width: 100%;
    display: flex;
    align-items: center;

    .book-img {
      width: 60px;
      height: 60px;
      border-radius: 6px;
      overflow: hidden;
      margin-right: 15px;

      img {
        object-fit: cover;
        object-position: center;
        width: 60px;
        height: 60px;
        object-fit: cover !important;
      }
    }

    h5 {
      transition: all ease-in-out 0.2s;
      margin-bottom: 0px;
      font-size: 14px;
    }

    p {
      font-size: 12px;
      margin-bottom: 0px;
      color: #6d7175;
    }
  }

  .arrow {
    h5 {
      font-size: 14px;
    }

    i {
      color: $black;
      font-size: 20px;
      transition: all ease-in-out 0.2s;
    }
  }

  .dropdown-toggle {
    background-color: transparent !important;
    border: 0;
  }

  .dropdown-toggle::after {
    display: none;
  }
}

// .dropdown{
//   .dropdown-toggle{
//     background-color: transparent !important;
//     color: $black;
//     border: 0;
//   }
//   .dropdown-toggle::after {
//     display: none;
//   }
// }

.cole-book-list-card:hover {
  box-shadow: $box-shadow;

  h5 {
    color: $primary;
  }

  .arrow {
    i {
      color: $primary;
    }
  }
}

.library-card:hover {
  cursor: pointer;

  .card-image {
    overflow: hidden;
  }

  img {
    // transform: scale(1.1);
    transition: transform 0.5s ease;
  }
}

.library-card {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid $border-light;

  .card-image {
    height: 150px;
    width: 100%;
  }

  img {
    width: 100%;
    object-fit: cover;
    height: 150px;
    width: 100%;
  }

  .content {
    padding: 15px;
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-row {
      display: flex;
      align-items: center;
    }

    .actions {
      span {
        padding: 4px 5px;
        border-radius: 3px;
        color: $border;
      }

      span:hover {
        background-color: #f5f5f5;
        color: black;
      }

      span.danger:hover {
        color: #dc3545;
      }

      span.primary:hover {
        color: #28a745;
      }
    }

    .icon {
      margin-right: 10px;
      height: 24px;
      width: 24px;
      border-radius: 3px;
      background: $lightgray;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: $border-light;
        font-size: 14px;
      }
    }

    span {
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.create-course-sibebar {
  padding: 10px;

  .heading {
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 20px;
    display: flex;
    align-items: flex-end;
    color: #202223;
  }

  .form-label {
    margin-bottom: 5px !important;
    font-weight: 400;
    text-transform: capitalize;
    font-size: 14px;
  }

  .form-control {
    height: 50px;
    color: #6d7175;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 7px;
    font-size: 14px;
  }

  .disable-field {
    background-color: #f5f5f5 !important;
  }

  // form-control:disabled{
  //   background-color: red !important;
  // }
  .form-select {
    height: 50px;
    color: #6d7175;
    // background: #ffffff;
    border: 1px solid #cccccc;
    outline: none;
    font-size: 14px;
  }

  .img-uploader {
    width: 100%;
    height: 183px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 7px;
    overflow: hidden;

    img {
      // height: 100%;
      object-fit: cover;
      width: 100%;
    }

    .icon-box {
      width: 107.2px;
      height: 111.39px;
      background-color: $lightgray;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 30px;
        color: $border-light;
      }
    }
  }

  .bottom-btns {
    padding-top: 60px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;

    .btn-light {
      height: 48px;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #202223;
      background: #f0f0f0;
      border-radius: 5px;
      margin-right: 15px;
      padding: 0px 20px;
    }

    .btn-primary {
      padding: 0px 28px;
      height: 48px;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: white;
      border-radius: 5px;
    }
  }

  .PhoneInput {
    display: flex;
  }

  .PhoneInputCountry {
    position: relative;

    .PhoneInputCountryIcon {
      position: absolute;
      padding-left: 10px;
      top: 2px;
      left: 0px;
      bottom: 2px;
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 35px;
        border: 1px solid #f5f5f5;
        border-radius: 3px;
      }
    }
  }

  .PhoneInputCountrySelect {
    padding: 20px;
    width: 75px !important;
    height: 50px !important;
    color: #6d7175 !important;
    background: #ffffff !important;
    border: 1px solid #cccccc !important;
    border-right: none !important;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    font-size: 14px !important;
    outline: none;
  }

  .PhoneInputInput {
    width: 100%;
    height: 50px;
    // color: white !important;
    background: #ffffff !important;
    border: 1px solid #cccccc !important;
    border-left: none !important;
    // border-radius: 7px !important;
    border-top-right-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
    font-size: 14px !important;
  }
}

.popup-modal {
  .section-name {
    font-size: 14px;
    border-radius: 6px;
    height: 42px;
    // border: 1px solid $border-light;
    margin-bottom: 15px;
  }

  .img-uploader {
    width: 100%;
    height: 183px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 7px;
    overflow: hidden;

    img {
      // height: 100%;
      object-fit: cover;
      width: 100%;
    }

    .icon-box {
      width: 107.2px;
      height: 111.39px;
      background-color: $lightgray;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 30px;
        color: $border-light;
      }
    }
  }
}

.confirmetion-popup {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 5px;
  }

  p {
    font-size: 14px;
  }

  .model-btns {
    width: 100%;
    margin-top: 20px;

    .btn {
      width: 100%;
      margin-left: 10px;
      border-radius: 4px;
      padding: 8px 25px;
      font-weight: 600;
    }
  }
}

.cole-book-list-card.header {
  padding: 0px 25px !important;
  min-height: 50px !important;
  border: none !important;

  h5 {
    font-weight: 600 !important;
    margin-bottom: 0px;
  }
}

.no-data-outer {
  img {
    width: 100px !important;
    height: 100px !important;
  }
}

.no-data-outer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 300px;

  img {
    height: 60px;
    margin-bottom: 20px;
  }

  h3 {
    font-weight: 500;
    font-size: 20px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
  }
}

.cole-book-list-card.header:hover {
  box-shadow: none !important;

  h5 {
    color: black !important;
  }
}

.error-div {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 80px;

  img {
    height: 350px;
  }

  h1 {
    font-size: 38px;
    font-weight: 600;
  }

  h2 {
    font-weight: 700;
    font-size: 180px;
    color: #dc3545;
  }

  h5 {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
  }
}

.loading-div {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 80px;

  img {
    height: 250px;
  }

  h1 {
    font-size: 38px;
    font-weight: 600;
  }

  h5 {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
  }
}

.list-check-box {
  height: 100%;
  display: flex;
  align-items: center;

  input {
    height: 20px;
    width: 20px;
  }
}

.data-error {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 400px;

  svg {
    margin-bottom: 20px;
  }

  .text-box {
    max-width: 700px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h3 {
      text-transform: capitalize;
      text-align: center;
      font-weight: 600;
    }

    .btn {
      height: 42px;
      padding: 0px 20px;
      font-size: 14px;
      border-radius: 4px;
    }
  }
}

.invite-model {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px !important;
  flex-direction: column;

  .btns {
    display: flex;
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;

    .btn {
      width: 48%;
      height: 42px;
      border-radius: 4px;
    }
  }

  h3 {
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .field {
    width: 100%;
    margin-bottom: 20px;

    input,
    select {
      height: 42px;
      border-radius: 4px;
    }
  }
}

.pagination {
  margin: 30px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  li {
    font-weight: 500;
    padding: 0px 5px;
    text-transform: capitalize;

    a {
      text-decoration: none;
      color: black;
    }
  }

  li.active {
    color: $main;
    font-weight: 600;
  }
}

.form-control,
.css-13cymwt-control,
.css-t3ipsp-control {
  min-height: 50px !important;
  color: #6d7175 !important;
  background: #ffffff !important;
  border: 1px solid #cccccc !important;
  border-radius: 7px !important;
  font-size: 14px !important;
}

.datalist-input-container {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.datalist-input.show {
  opacity: 1;
}

.note {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 600;
}

.offcanvas-body {
  a {
    text-decoration: none;
  }
}

.book-detail.role {
  display: flex;
  flex-wrap: wrap;

  h5 {
    margin-right: 4px;
    line-break: strict;
  }
}

.loading-div {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 80px;

  .cube-loading-outer {
    //custom mixins
    @mixin transform($transform) {
      -webkit-transform: $transform;
      -moz-transform: $transform;
      transform: $transform;
    }

    @mixin animation($animation) {
      -webkit-animation: $animation;
      -moz-animation: $animation;
      animation: $animation;
    }

    @mixin keyframes($name, $name1) {
      @-webkit-keyframes #{$name} {
        @content;
      }

      @-moz-keyframes #{$name} {
        @content;
      }

      @-ms-keyframes #{$name} {
        @content;
      }

      @keyframes #{$name} {
        @content;
      }
    }

    @mixin opacity($opacity) {
      -webkit-opacity: $opacity;
      -moz-opacity: $opacity;
      opacity: $opacity;
    }

    @mixin border-radius($radius) {
      -webkit-border-radius: $radius;
      -moz-border-radius: $radius;
      border-radius: $radius;
    }

    position: relative;
    height: 500px;
    //variables
    $size: 50px;

    //cube only
    .cube-folding {
      width: $size;
      height: $size;
      display: inline-block;
      @include transform(rotate(45deg));
      font-size: 0;

      span {
        position: relative;
        width: $size/2;
        height: $size/2;
        @include transform(scale(1.1));
        display: inline-block;

        &::before {
          content: "";
          background-color: $main;
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: $size/2;
          height: $size/2;
          -moz-transform-origin: 100% 100%;
          -ms-transform-origin: 100% 100%;
          -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
          @include animation(folding 2.5s infinite linear both);
        }
      }

      .leaf2 {
        @include transform(rotateZ(90deg) scale(1.1));

        &::before {
          -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
          background-color: darken($main, 5%);
        }
      }

      .leaf3 {
        @include transform(rotateZ(270deg) scale(1.1));

        &::before {
          -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
          background-color: darken($main, 5%);
        }
      }

      .leaf4 {
        @include transform(rotateZ(180deg) scale(1.1));

        &::before {
          -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
          background-color: darken($main, 10%);
        }
      }
    }

    //animation
    @include keyframes(folding, folding animation) {
      0%,
      10% {
        @include transform(perspective(140px) rotateX(-180deg));
        @include opacity(0);
      }

      25%,
      75% {
        @include transform(perspective(140px) rotateX(0deg));
        @include opacity(1);
      }

      90%,
      100% {
        @include transform(perspective(140px) rotateY(180deg));
        @include opacity(0);
      }
    }

    //with loading text and shadow
    .cube-wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -$size;
      margin-left: -$size;
      width: $size * 2;
      height: $size * 2;
      text-align: center;

      //shadow
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -20px;
        margin: auto;
        width: $size * 1.8;
        height: 6px;
        z-index: 1;
        background-color: rgba(black, 0.1);
        -webkit-filter: blur(2px);
        filter: blur(2px);
        @include border-radius(100%);
        @include animation(shadow 0.5s ease infinite alternate);
      }

      .loading {
        // font-size: 12px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.1em;
        display: block;
        color: $main;
        position: relative;
        top: $size/2;
        z-index: 2;
        @include animation(text 0.5s ease infinite alternate);
      }

      @include keyframes(text, text animation) {
        100% {
          top: ($size/2) + 10;
        }
      }

      @include keyframes(shadow, shadow animation) {
        100% {
          bottom: -18px;
          width: $size * 2;
        }
      }
    }
  }
}

.invites-table {
  .table-header {
    display: flex;
    justify-content: space-evenly;
    background: #f8f8f8;
    border-radius: 6px;
    padding: 7px 30px;

    .table-item {
      width: 170%;
      display: flex;
      justify-content: center;

      p {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 0px;
        color: #202223;
      }
    }

    .table-item:first-child {
      display: flex;
      justify-content: flex-start;
    }

    .table-item:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }

  .table-body {
    .table-row {
      display: flex;
      justify-content: space-evenly;
      padding: 20px 30px;
      align-items: center;
      border-bottom: 1px solid #cccccc;

      .table-item {
        width: 50%;
        display: flex;
        justify-content: center;

        p {
          font-weight: 400;
          display: flex;
          flex-wrap: wrap;
          font-size: 14px;
          margin-bottom: 0px;
          color: #202223;
        }

        .img-list {
          height: 40px !important;
          width: 40px !important;
          border-radius: 4px;
        }
      }

      .btn-light {
        height: 30px;
        width: 30px;
        border-radius: 100px;
        background: transparent;
        border: none;
      }

      .btn-light:hover {
        background: #f5f5f5;
      }

      .btn-success {
        // background: #007f5f;
        height: 24px;
        padding: 0px 10px;
        font-size: 12px;
        border-radius: 5px;
      }

      .table-item:first-child {
        display: flex;
        justify-content: flex-start;
      }

      .table-item:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.roles-body {
  .page-title {
    font-weight: 600;
    font-size: 18px;
    color: #202223;
  }

  .discription {
    font-weight: 400;
    font-size: 12px;
    color: #202223;
  }

  .roles-card {
    min-height: 60px;
    width: 100%;
    margin: 20px 0px;
    border: 1px solid #cccccc;

    .btn {
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
    }

    .card-header {
      min-height: 60px;
      display: flex;
      flex-direction: column;
      // align-items: center;
      padding: 20px 24px;
      border-bottom: 1px solid #cccccc;

      h4 {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 0px;
        color: #202223;
      }

      p {
        margin-bottom: 0px;
        font-weight: 400;
        font-size: 12px;
        color: #202223;
      }
    }

    .card-body {
      padding: 24px;
      display: flex;
      justify-content: space-between;

      h6 {
        font-weight: 600;
        font-size: 14px;
        color: #09254a;
        margin-bottom: 5px;
      }

      p {
        font-weight: 400;
        font-size: 12px;
        color: #202223;
      }

      a {
        color: $main;
        text-decoration: none;
      }
    }
  }
}

.general {
  // padding: 126px 40px;
  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #c9c4c4;
    padding: 22px 13px;
    border-radius: 7px;
    margin-bottom: 19px;

    h2 {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}

.Gateways {
  .g-header {
    .colm {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .colm:first-child {
      justify-content: flex-start !important;
      width: 150%;
    }

    .colm:last-child {
      justify-content: flex-end !important;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 32px;
    width: 100%;
    margin-bottom: 0px;

    h2 {
      font-size: 14px;
      color: black;
      font-weight: 600 !important;
      font-weight: 500;
      margin-bottom: 0;
    }

    .side-heading {
      justify-content: center;
      align-items: center;
      display: flex;

      .connected {
        // margin-right: 277px;
        font-size: 14px;
        font-weight: 600;
        color: black;

        p {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 600;
          color: black;
        }
      }
    }
  }

  .g-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #c9c4c4;
    padding: 24px 32px;
    border-radius: 7px;
    width: 100%;
    margin-bottom: 15px;

    .colm {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .colm:first-child {
      width: 150%;
      justify-content: flex-start !important;
    }

    .colm:last-child {
      justify-content: flex-end !important;
    }

    h2 {
      font-size: 16px;
      color: #6073e4;
      font-weight: 700 !important;
      font-weight: 500;
      margin-bottom: 0;
    }

    h3 {
      font-size: 14px;
      font-weight: 600;
      color: #4cbc50;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.tags-row {
  .g-header {
    .colm {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .colm:first-child {
      justify-content: flex-start !important;
      width: 150%;
    }

    .colm:last-child {
      justify-content: flex-end !important;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 32px;
    width: 100%;
    height: 72px;
    margin-bottom: 0px;

    h2 {
      font-size: 14px;
      color: black;
      font-weight: 600 !important;
      font-weight: 500;
      margin-bottom: 0;
    }

    .side-heading {
      justify-content: center;
      align-items: center;
      display: flex;

      .connected {
        // margin-right: 277px;
        font-size: 14px;
        font-weight: 600;
        color: black;

        p {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 600;
          color: black;
        }
      }
    }
  }

  .g-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #c9c4c4;
    padding: 20px 30px;
    border-radius: 7px;
    width: 100%;
    margin-bottom: 15px;

    .colm {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .colm:first-child {
      width: 150%;
      justify-content: flex-start !important;
    }

    .colm:last-child {
      justify-content: flex-end !important;
    }

    h2 {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

.create-team {
  width: 480px !important;

  .offcanvas-body {
    padding: 0px !important;
  }

  .create-tag-bar {
    .main-header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100vh;
      padding: 30px;

      .up {
        h2 {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 45px;
        }
      }

      .tag-input {
        h3 {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 8px;
          color: #202223;
        }
      }

      .member {
        color: #2181fa;
        font-size: 14px;
        display: flex;
        margin-bottom: 25px;
        align-items: center;
        font-weight: 400;

        h2 {
          margin-left: 10px;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 0;
        }
      }

      .mention {
        p {
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 38px;

          span {
            color: #2181fa;
          }
        }
      }

      .search-input {
        position: relative;
      }

      .fa-light {
        position: absolute;
        right: 18px;
        top: 16px;
        z-index: 99;
      }

      .end-secction {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .btn {
          width: 155px;
          height: 48px;
          font-size: 16px;
          margin-left: 10px;
        }
      }
    }

    .two-input {
      display: flex;
      justify-content: space-between;

      .search-input {
        margin-right: 20px;
      }
    }
  }
}

.add-team-member {
  width: 480px !important;

  .offcanvas-body {
    padding: 0px !important;
  }

  .add-team {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;

    .inner-addteam {
      h3 {
        font-size: 20px;
        margin-bottom: 30px;
        font-weight: 600;
      }
    }

    .tag-input {
      h3 {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 8px;
        color: #202223;
      }

      .mb-3 {
        margin-bottom: 30px !important;
      }
    }

    .add-checkbox {
      h2 {
        font-size: 14px;
        margin-bottom: 30px;
        font-weight: 400;
      }
    }

    .input-group-text {
      background-color: white;
      border: none;
    }

    .end-secction {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .btn {
        width: 155px;
        height: 48px;
        font-size: 16px;
        margin-left: 10px;
      }
    }

    .add-checkbox-text {
      display: flex;

      .check-text {
        margin-top: 10px;

        h2 {
          font-weight: 600;
          color: #000000;
          margin-bottom: 4px;
          font-size: 14px;
        }

        h3 {
          font-weight: 600;
          color: #2181fa;
          margin-bottom: 4px;
          font-size: 14px;
        }

        p {
          font-size: 12px;

          span {
            color: #2181fa;
          }
        }
      }
    }
  }
}

.about-this-role {
  width: 480px !important;

  .offcanvas-body {
    padding: 0px !important;
  }

  .about {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    padding: 30px;

    .inner-about {
      h2 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 45px;
      }
    }

    .about-content {
      h1 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 4px;
      }

      p {
        font-size: 12px;
        font-weight: 400;
      }
    }

    .end-secction {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .btn {
        width: 155px;
        height: 48px;
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }
}

.create-role {
  width: 480px !important;

  .offcanvas-body {
    padding: 0px !important;
  }

  .create-roll {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    padding: 30px;

    .inner-header {
      h2 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 56px;
      }
    }

    .tag-input {
      margin-bottom: 25px;

      h3 {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 8px;
        color: #202223;
      }
    }

    .member {
      color: #2181fa;
      font-size: 14px;
      display: flex;
      margin-bottom: 35px;
      align-items: center;
      font-weight: 400;

      h2 {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    .end-secction {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .btn {
        width: 155px;
        height: 48px;
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }
}

.edit-team-member {
  .modal-body {
    padding: 0px;
  }

  .add-team {
    padding: 30px;

    .inner-addteam {
      h3 {
        font-size: 20px;
        margin-bottom: 30px;
        font-weight: 600;
      }
    }

    .tag-input {
      h3 {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 8px;
        color: #202223;
      }

      .mb-3 {
        margin-bottom: 30px !important;
      }
    }

    .add-checkbox {
      h2 {
        font-size: 14px;
        margin-bottom: 36px;
        font-weight: 400;
      }
    }

    .input-group-text {
      background-color: white;
      // height: 8px;
      // width: 8px;
      border: none;
    }

    .add-checkbox-text {
      display: flex;

      .check-text {
        margin-top: 10px;

        h2 {
          font-weight: 600;
          color: #000000;
          margin-bottom: 4px;
          font-size: 14px;
        }

        h3 {
          font-weight: 600;
          color: #2181fa;
          margin-bottom: 4px;
          font-size: 14px;
        }

        p {
          font-size: 12px;

          span {
            color: #2181fa;
          }
        }
      }
    }
  }

  .right {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .up-head {
      h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 30px;
      }

      p {
        font-size: 12px;
        font-weight: 600;
      }

      span {
        font-size: 12px;
        font-weight: 400;
      }
    }

    .end-secction {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .btn {
        width: 155px;
        height: 48px;
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }
}

.gateway-detail {
  width: 480px !important;

  .offcanvas-body {
    padding: 0px !important;
  }

  .goback {
    padding: 30px;

    .up {
      display: flex;
      align-items: center;
      margin-bottom: 70px;

      .fa-solid {
        font-size: 12px;
        font-weight: 400;
      }

      h3 {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 0;
        margin-left: 11px;
      }
    }

    .strip {
      height: 70px;
      width: 70px;
      overflow: hidden;
      border-radius: 10px;
      margin-bottom: 30px !important;

      img {
        height: 70px;
        width: 70px;
        border-radius: 10px;
        object-fit: cover;
      }
    }

    .content {
      h2 {
        font-size: 18px;
        font-weight: 600 !important;
        margin-bottom: 17px;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 14px;
        color: #6d7175;
      }

      .second-p {
        margin-bottom: 42px;
      }

      .btn-primary {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 21px;
        padding: 12px 0px;
        width: 100%;
        height: 48px;
      }
    }

    .lower {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 400;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      span {
        font-size: 14px;
        margin-left: 5px;
        color: blue;
      }
    }
  }
}

.add-gateway {
  width: 480px !important;

  .offcanvas-body {
    padding: 0px !important;
  }

  .gateway {
    // background-color: #F36737;
    .main-section {
      padding: 30px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        h2 {
          font-size: 20px;
          font-weight: 600;
        }

        i {
          font-size: 16px;
        }
      }

      .mid-section {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0px;
        border-bottom: 1px solid #cccccc;

        h2 {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 0;
        }

        P {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 0;
        }

        .image {
          height: 70px;
          width: 70px;
          border-radius: 10px;
          object-fit: cover;

          img {
            height: 70px;
            width: 70px;
            border-radius: 10px;
            object-fit: cover;
          }
        }

        .stripe {
          margin-left: 13px;

          .green {
            display: flex;
            align-items: center;
          }

          .coming {
            margin-left: 10px;
            padding: 0px 5px;
            background-color: #4cbc9a;
            font-size: 10px;
            font-weight: 300;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

.create-tag {
  width: 480px !important;

  .offcanvas-body {
    padding: 0px !important;
  }

  .create-tag-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    padding: 30px;

    .break {
      margin-bottom: 30px;
    }

    .header {
      h2 {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .desc {
      h2 {
        margin-bottom: 16px !important;
      }

      .mb-3 {
        margin-bottom: 28px !important;
      }
    }

    .key-section {
      h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      h4 {
        font-size: 23px;
        font-weight: 600;
        margin-bottom: 26px;
      }

      .two-input {
        h3 {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 8px;
        }

        .mb-3 {
          margin-bottom: 24px !important;
        }
      }

      .king {
        margin-right: 20px;
      }

      .input-group > .form-control {
        border: 1px solid black;
        height: 50px;
      }

      .group-feild {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .add-value {
        display: flex;
        align-items: center;

        color: #2181fa;

        h5 {
          font-size: 14px;
          font-weight: 600;
          margin-left: 18px;
          margin-bottom: 0;
        }
      }
    }

    .end-secction {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .btn {
        width: 155px;
        height: 48px;
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }
}

.space-details {
  width: 750px !important;

  .offcanvas-body {
    padding: 0px !important;
  }

  .main-section {
    .whole-section {
      padding: 30px;

      .accordion {
        margin-bottom: 15px;
      }
    }

    .inner-section {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 53px;

      h3 {
        // margin-bottom: 54px;
        font-size: 20px;
        font-weight: 400;
      }

      i {
        margin-bottom: 0;
      }
    }

    .accordion {
      .accordion-item {
        .accordion-button {
          font-size: 16px !important;
          font-weight: 500;
          color: black;
        }

        .accordion-button:not(.collapsed) {
          background-color: #ffffff;
        }
      }
    }

    .accordion-inner {
      .inbox {
        h5 {
          font-size: 14px;
          font-weight: 600;
        }

        p {
          font-size: 12px;
          font-weight: 400;
          color: #6d7175;
        }
      }
    }
  }
}

.branding {
  width: 750px !important;

  .offcanvas-body {
    padding: 0px !important;
  }

  .whole-branding {
    padding: 30px;
  }

  .brand-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 54px;

    align-items: center;

    div {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .form-check {
    margin-bottom: 24px !important;

    .form-check-label {
      margin-left: 10px !important;
    }

    .ilm {
      margin-right: 25px !important;
    }
  }

  .accordion {
    .accordion-button:not(.collapsed) {
      background-color: white !important;
      color: black;
    }

    .text-heading {
      p {
        font-size: 14px !important;
        font-weight: 400;
        color: #6d7175;
        margin-bottom: 31px;
        margin-left: 24px;
      }

      button {
        background-color: #cccccc;
        color: white;
        padding: 12px 38px;
        font-size: 12px;
        margin-left: 24px;
      }
    }

    .ilm {
      .layer {
        margin-bottom: 27px !important;
      }
    }
  }

  .ilm {
    margin-left: 25px !important;
  }

  .save-btn {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .btn {
      background-color: #2181fa !important;
      color: white !important;
      padding: 12px 38px !important;
      font-size: 16px !important;
      margin-left: 24px !important;
    }
  }
}

.legal-compliance {
  width: 750px !important;

  .offcanvas-body {
    padding: 0px !important;
  }

  .legal-accordion {
    padding: 30px;
  }

  .brand-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 54px;

    align-items: center;

    div {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .legal-accordion {
    .accordion-button:not(.collapsed) {
      background-color: white;
      color: black !important;
    }

    .inner-legal {
      margin-bottom: 30px;

      .left {
        h2 {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .right {
        font-size: 14px;
        font-weight: 400;
        color: #2181fa;
      }
    }
  }
}

.Organizationn {
  .all-org {
    margin-bottom: 20px;
    padding: 15px 20px;
    display: flex;
    border-radius: 6px;
    justify-content: space-between;
    align-items: center;
    background: #fbfbfb;

    .side {
      width: 100%;
      display: flex;
      justify-content: center;

      h2 {
        margin-bottom: 0px;
        font-size: 14px !important;
        font-weight: 500 !important;
      }
    }

    .side:first-child {
      width: 150%;
      justify-content: flex-start;
    }

    .side:last-child {
      width: 50%;
      justify-content: flex-end;
    }

    .inbox {
      display: flex;
      align-items: center;

      img {
        height: 56px;
        width: 56px;
        border-radius: 6px;
        overflow: hidden;
        object-fit: cover;
      }

      .content {
        padding-left: 15px;

        h2 {
          font-size: 14px;
          margin-bottom: 4px;
          font-weight: 600;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 0px;
        }
      }
    }
  }

  .all-org-header {
    padding: 15px 20px;
    display: flex;
    border-radius: 6px;
    justify-content: space-between;
    align-items: center;

    .side {
      width: 100%;
      display: flex;
      justify-content: center;

      h2 {
        font-size: 14px !important;
        font-weight: 700 !important;
      }
    }

    .side:first-child {
      width: 150%;
      justify-content: flex-start;
    }

    .side:last-child {
      width: 50%;
      justify-content: flex-end;
    }
  }
}

.inner-brand {
  .left {
    margin-bottom: 20px;
    padding: 20px 20px;
    border: 1px solid #d7cccc;
    border-radius: 10px;
    margin-top: 10px;

    .inner-left {
      .hero-section {
        h2 {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 12px;
        }

        p {
          font-size: 14px;
          color: #8c9196;
          font-weight: 400;
        }
      }
    }

    .mid-section {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
      }

      .round-color {
        height: 50px !important;
        width: 50px !important;
        border: none !important;
        border-radius: 100px !important;
        overflow: hidden;
        padding: 0px;
      }

      .inner-color {
        display: flex;
        background: #f5f5f5;
        align-items: center !important;
        justify-content: space-around;
        border-radius: 2px;

        .square-color {
          height: 20px !important;
          width: 20px !important;
          border-radius: 2px !important;
          overflow: hidden;
          padding: 0px;
        }

        p {
          padding: 0px 5px;
          margin-bottom: 0;
          font-size: 12px;
          font-weight: 600;
          color: #6d7175;
        }
      }
    }
  }

  .right {
    background: #f8f8f8;
    border-radius: 6px;

    .heading {
      padding: 20px 15px;

      h2 {
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: 600;
      }
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
  }
}

.model-btns-row .btn {
  width: 200px !important;
  font-size: 14px;
  border-radius: 4px;
  text-transform: uppercase;
}

.dashboard-body {
  // padding: 73px 24px 60px;
  padding: 40px 72px 60px;

  @media only screen and (max-width: 1199px) and (min-width: 768px) {
    padding: 73px 36px 60px;
  }

  .console-tabs {
    margin-bottom: 20px !important;
  }

  .body-content {
    max-width: 1300px;
    margin: auto;

    .console-wrapper {
      margin: 0px !important;

      h3 {
        font-size: 32px !important;
        line-height: 40px !important;
        font-weight: 600 !important;
        color: #2d2f31;
        padding-bottom: 24px;
        border-bottom: 1px solid #dee1e6;
      }
    }

    .body-content-header {
      border: 1px solid red;
      padding: 32px 31px 32px 66px;
      border: 1px solid #dee1e6;
      box-shadow: 0px 4px 10px 0px #00000012;
      border-radius: 10px;

      .name {
        gap: 20px !important;

        .body-side-content {
          h5 {
            margin-top: 18px;
          }
        }
      }

      img {
        width: 100px;
        height: 100px;
      }
    }

    .name {
      display: flex;
      align-items: center;
      gap: 30px;

      @media screen and (max-width: 767px) {
        gap: 10px;
      }

      #dropdown-button-drop-end {
        border: none;
        background: transparent;
        padding: 0;

        svg,
        img {
          width: 35px;
          height: 35px;
          color: #2d2f31;

          @media screen and (max-width: 767px) {
            width: 20px;
            height: 20px;
          }
        }
      }

      .dropdown-menu {
        max-width: 240px;
        min-width: 200px;
        box-shadow: 0px 7px 14px 0px #0000001a;
        border: 1px solid #dee1e6;
        width: 100%;
        top: -100% !important;

        .dropdown-item {
          background: transparent;

          &:hover,
          &.active {
            background: transparent !important;
            color: #2d2f31 !important;
          }

          p {
            width: 100%;
            text-wrap: wrap;
            font-size: 10px;
            line-height: 24px;
            color: #2d2f31;
            margin-bottom: 0;
          }
        }
      }
    }

    .role {
      display: inline-flex;
      align-items: center;
      gap: 10px;
      margin: 20px 0 30px;
    }

    .badge {
      border-radius: 5px !important;
      margin-left: 8px;
      font-size: 13px;
      line-height: 10px;
      height: 32px;
      width: 82px;
      padding: 10px 6px;
      margin-top: 10px;
    }

    h1 {
      font-weight: 600;
      font-size: 32px;
      letter-spacing: -0.02em;
      color: #2d2f31;
      margin-bottom: 0;

      span {
        font-weight: 400;
        font-size: 32px;
        color: #2d2f31;
      }
    }

    h5 {
      font-weight: 600;
      font-size: 16px;
      letter-spacing: -0.02em;
      margin: 15px 0 33px;
      color: #202223;

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }

    a {
      text-decoration: none;
      color: $main;
      text-transform: capitalize;
    }

    h6 {
      font-weight: 700;
      font-size: 12px;
      margin-bottom: 0;
      color: #202223;
      margin-bottom: 0 !important;

      span {
        text-transform: capitalize;
        font-weight: 500;
      }
    }

    h3 {
      font-weight: 600;
      font-size: 23px;
      letter-spacing: -0.02em;
      color: #000000;
      margin-bottom: 40px;
    }

    .add-btns {
      display: flex;
      margin-bottom: 60px;

      .btn {
        margin-right: 10px;
        width: 196px;
        height: 40px;
        background: #ffffff;
        border: 2px solid #2181fa;
        border-radius: 8px;
        font-size: 14px;
        // box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px !important;
        text-align: center;
        color: #2181fa;

        i {
          margin-right: 6px;
          font-size: 13px;
        }
      }
    }
  }

  .console-header-icons {
    display: flex;
    align-items: center;
    gap: 40px;

    .icon1 {
      display: flex;
      align-items: center;
      gap: 6px;

      img,
      svg {
        width: 36px;
        height: 36px;
        object-fit: cover;

        &.guardian {
          width: 24px;
          height: 24px;
        }
      }

      span {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #2d2f31;
      }
    }

    .icon2 {
      display: flex;
      align-items: center;
      padding: 20px;
      border-radius: 10px;
      border: 1px solid #dee1e6;
      box-shadow: 0px 1px 3px 0px #0000000f;
      max-width: 244px;
      width: 100%;
      gap: 14px;

      img {
        width: 52px;
        height: 52px;
        object-fit: contain;
      }

      span {
        font-size: 48px;
        font-weight: 600;
        line-height: 40px;
      }
    }
  }
}

.quick-access-card {
  height: 100%;
  border-radius: 10px;
  border: 1px solid var(--line, #dee1e6);
  background: var(--White, #fff);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  .access-box {
    padding: 17px 17px 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .icon {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        height: 24px;
        width: 24px;
        color: $main;
      }

      h4 {
        margin-bottom: 7px;
        color: #2d2f31;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0;
      }
    }

    .text {
      flex: 1;

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #6d7175;
        margin-bottom: 0px;
        text-align: center;
        padding: 10px 45px;
      }
    }
  }
}

header.analytics-header {
  .dropdown-toggle {
    padding: 0px;
    border-radius: 100px;
    border: none;
    height: 40px;

    > img,
    svg {
      color: black;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu {
    position: absolute !important;
    top: 50px !important;
    padding: 24px;
    width: 365px !important;
    height: 400px !important;
    border-radius: 8px;
    background: #fff;
    border: none;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
    z-index: 999;
    transform: none !important;
    // inset: 52px auto auto auto !important;
    right: 0 !important;
    padding: 10px 15px;
    // right: 0px !important;
    left: 0px !important;

    .user-profile {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-bottom: 1px solid $border;
      margin-bottom: 10px;

      .profile {
        border-radius: 100px;
        // border: 1px solid var(--fade-black10, rgba(27, 31, 35, 0.1));
        width: 110px;
        height: 110px;
        margin-bottom: 15px;
        position: relative;

        img {
          height: 100%;
          border-radius: 100px;
          width: 100%;
          object-fit: cover;
        }

        .status {
          position: absolute;
          width: 20px;
          height: 20px;
          flex-shrink: 0;
          border-radius: 100px;
          background: $border;
          border: 1px solid white;
          bottom: 5px;
          right: 5px;
          z-index: 99999999;
        }

        .status.active {
          background: #22c55e;
        }
      }

      h3 {
        margin-bottom: 15px;
        text-align: center;
        color: var(--Primary-text, #2d2f31);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        text-transform: uppercase;
      }

      h6 {
        color: #2181fa;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 15px;
      }
    }

    .options {
      .option {
        padding: 10px 0px;
        display: flex;
        cursor: pointer;

        svg {
          margin-right: 8px;
        }

        span {
          color: var(--Primary-text, #2d2f31);
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          /* 150% */
        }
      }

      .status {
        padding: 8px 0px;
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;

        .stat {
          display: flex;
          align-items: center;
        }

        .dot {
          margin-right: 8px;
          width: 15px;
          height: 15px;
          background: #ef4444;
          border-radius: 100%;
        }

        .dot.active {
          background: #22c55e;
        }

        span {
          color: var(--Primary-text, #2d2f31);
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          /* 150% */
        }
      }

      .option:hover {
        span {
          color: #2181fa;
        }

        svg path {
          fill: #2181fa !important;
        }
      }

      .option:last-child {
        padding-bottom: 0px;
        border-top: 1px solid $border;
      }
    }
  }

  .portal {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 5px;

    .icon {
      width: 36px;
      height: 36px;
      flex-shrink: 0;
      border-radius: 4px;
      background: white !important;
      margin-right: 12px;
      filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
      display: flex;
      align-items: center;
      justify-content: center;

      img,
      svg {
        width: 20px !important;
        height: 20px !important;
        margin: 0 0 0 0;
        filter: none;
      }
    }

    svg,
    img {
      width: 36px !important;
      height: 36px !important;
      flex-shrink: 0;
      border-radius: 4px;
      background: white !important;
      margin-right: 12px;
      filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
    }

    span {
      color: var(--Primary-text, #2d2f31);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 0px;
      margin-left: 8px;
      margin-left: 8px;
    }
  }

  .portal:hover {
    span {
      color: #2181fa;
    }
  }
}

//content model
.content-modal {
  .image-tab img {
    object-fit: cover;
    margin-block: 10px;
  }

  .nav-tabs {
    border-bottom: 1px solid rgb(0 0 0 / 7%) !important;

    .nav-link {
      color: rgb(137 137 137) !important;
      border: 0 !important;
      font-size: 12px;
      cursor: pointer;
      transition: color 0.3s ease;
    }

    .nav-link:hover {
      color: rgb(38 38 39) !important;
    }

    .active {
      border-bottom: 2px solid rgb(38 38 39) !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      color: rgb(38 38 39) !important;
      transition: color 0.2s ease;
    }
  }

  .lkjDVC,
  .iNitOb,
  .uploader-drag-drop label {
    padding-top: 100px;
    flex-direction: column;
    flex: 1 !important;
    height: 100%;
    background-color: #e3e3e3 !important;

    svg {
      width: 80px !important;
      height: 80px !important;
    }

    .dmajdp {
      span {
        font-size: 14px;
        color: $primary;
      }
    }
  }

  .nav-item {
    padding: 5px 15px 5px 15px;

    button {
      font-size: 14px;
      // color: rgb(38 38 39);
    }
  }
}

.content-modal {
  .image-tab {
    img {
      height: 150px;
      width: 100%;
    }
  }

  .video-tab {
    video {
      width: 100%;
      height: 120px;
    }
  }

  .gellary-tab {
    .btn-tab-outer {
      button {
        font-size: 14px;
      }

      .active {
        background-color: #444444 !important;
        color: #fff;
      }
    }

    img {
      width: 100%;
      height: 150px;
    }

    video {
      width: 100%;
      height: 150px;
    }

    audio {
      width: 100%;
    }
  }
}

.content-modal {
  .tabs-heading {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cccccc;
    padding: 0px 10px;

    h4 {
      margin-bottom: 0px;
    }
  }

  .library-tabs {
    // padding: 10px 40px;
    width: 100%;
    overflow-y: auto;
    max-height: 80vh !important;

    .nav-tabs {
      border-bottom: 1px solid #cccccc !important;

      .nav-link {
        color: #454545 !important;
        background-color: white !important;
        font-size: 14px;
        font-family: sans-serif;
        font-weight: 500;
      }

      .nav-link.active {
        font-size: 15px;
        // margin-left: -14px;
      }

      // .nav-link:hover {
      //   // border-bottom: 2px solid rgb(150, 5, 5) !important;
      //   border-bottom-left-radius: 0px;
      //   border-bottom-right-radius: 0px;
      //   // color: rgb(150, 5, 5) !important;
      //   transition: 0ms;
      //   font-family: sans-serif;
      //   border-radius: 10px;
      //   font-size: medium;
      //   transition: 0.3s ease-in-out;
      // }
    }

    .uploader-drag-drop {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      > label {
        .jWkLDY,
        .sc-eqUAAy {
          flex-grow: unset !important;
          align-items: center !important;
          gap: 2px;

          .sc-fqkvVR.kFhUBM {
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.content-modal .library-tabs::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 6px;
}

.content-modal .library-tabs::-webkit-scrollbar {
  width: 0.4rem;
}

.content-modal .tabs-heading {
  padding: 0px 15px;
}

.content-modal .library-tabs .nav-tabs {
  padding-bottom: 10px;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

// creat model
.index-create-model {
  .modal-md {
    // min-width: 550px;
  }

  .create-course-section {
    .add-img-card {
      height: 200px;
      width: 200px;
      margin: auto;
      border-radius: 10px;
      border: 1px solid #dee1e6;
      background: var(--White, #fff);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 767px) {
        width: 170px;
        height: 170px;
      }

      .u-img {
        width: 100%;
        height: 100%;
      }

      .plus {
        height: 44px;
        width: 44px;
        border-radius: 4px;
        border: 1px solid var(--line, #dee1e6);
        background: var(--White, #fff);
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        svg {
          height: 25px;
          width: 25px;
        }
      }

      h5 {
        color: var(--On-Surface-Border, #8c9196);
        text-align: center;

        /* Paragraph/P1/Regular */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 128.571% */
        margin-bottom: 0px;
      }

      p {
        color: var(--On-Surface-Border, #8c9196);
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        margin-bottom: 0px;
        font-weight: 400;
        line-height: 18px;
      }
    }

    .form-control {
      display: flex;
      // width: 408px !important;
      // padding: 10px 15px;
      align-items: center;
      // margin: auto;
      // margin-top: 20px;
      color: #000;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      border-radius: 7px !important;
      border: 1px solid var(--line, #dee1e6) !important;
      background: var(--White, #fff) !important;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1) !important;
    }
  }

  .modal-content {
    border-radius: 12px;
    border: 1px solid var(--line, #dee1e6) !important;
    background: var(--White, #fff);
    box-shadow: 5px 31px 68px 0px rgba(0, 0, 0, 0.1),
      22px 122px 124px 0px rgba(0, 0, 0, 0.09),
      49px 276px 168px 0px rgba(0, 0, 0, 0.05),
      86px 490px 199px 0px rgba(0, 0, 0, 0.01),
      135px 765px 218px 0px rgba(0, 0, 0, 0);
  }

  .modal-body {
    padding: 0px;
  }

  .create-folder-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icon {
      display: flex;
      width: 36px;
      height: 36px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid var(--line, #dee1e6);
      background: var(--White, #fff);
      margin-bottom: 15px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

      svg {
        height: 24px;
        width: 24px;
      }
    }

    h4 {
      color: var(--Primary-text, #2d2f31);
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 30px;
    }

    h6 {
      color: var(--Primary-text, #2d2f31);
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    p {
      max-width: 310px;
      color: var(--text-2, #a29e9e);
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 185.714% */
    }

    .form-control {
      display: flex;
      width: 408px;
      height: 62px;
      padding: 16px 10px 16px 20px;
      align-items: center;
      border-radius: 7px;
      border: 1px solid var(--line, #dee1e6) !important;
      background: var(--White, #fff) !important;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1) !important;
      // color: var(--text-2, #a29e9e);

      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      /* 128.571% */
    }
  }

  .delete-folder-section {
    display: flex;
    padding: 40px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icon {
      display: flex;
      width: 36px;
      height: 36px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid var(--line, #dee1e6);
      background: var(--White, #fff);
      margin-bottom: 15px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    .detail {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      h6 {
        margin: 0px 10px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 10px;
        }
      }
    }

    h4 {
      color: var(--Primary-text, #2d2f31);
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 30px;
    }

    h6 {
      color: var(--Primary-text, #2d2f31);
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    p {
      max-width: 310px;
      color: var(--text-2, #a29e9e);
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      margin-bottom: 30px;
      /* 185.714% */
    }

    .form-control {
      display: flex;
      width: 408px;
      height: 62px;
      padding: 16px 10px 16px 20px;
      align-items: center;
      border-radius: 7px;
      border: 1px solid var(--line, #dee1e6);
      background: var(--White, #fff);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1) !important;
      color: var(--text-2, #a29e9e);

      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      /* 128.571% */
    }
  }

  .move-folder-section {
    .move-head {
      padding: 10px 20px;
      border-bottom: 1px solid var(--line, #dee1e6);
      background: var(--White, #fff);
      display: flex;
      justify-content: space-between;
      align-items: center;

      h5 {
        color: var(--Primary-text, #2d2f31);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 0px;

        svg {
          margin: 10px;
        }
      }
    }

    .move-card {
      margin-top: 20px;
      display: flex;
      width: 100%;
      height: 62px;
      padding: 0px 20px 0px 15px;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--line, #dee1e6);
      background: var(--White, #fff);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

      .folder-card {
        display: flex;
        align-items: center;

        .icon {
          display: flex;
          width: 36px;
          height: 36px;
          padding: 9px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          margin-right: 15px;
          flex-shrink: 0;
          border-radius: 4px;
          background: var(--White, #fff);
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
        }

        span {
          color: var(--On-Surface-Text-Colour, #202223);
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          /* 171.429% */
        }
      }
    }
  }

  .m-header {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dee1e6;

    h3 {
      margin-bottom: 0px;
      color: var(--Primary-text, #2d2f31);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
    }

    i {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .m-footer {
    padding: 20px 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #dee1e6;

    .btn-cancel {
      border-radius: 5px;
      background: var(--Surface-background, #f0f0f0);
      display: flex;
      text-align: center;
      justify-content: center;
      width: 129px;
      height: 36px;
      padding: 12px 18px;
      align-items: center;
      margin-left: 20px;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    .btn-save {
      border-radius: 5px;
      background: var(--Main-Color-Main-Color, #2181fa);
      display: flex;
      color: var(--White, #fff);
      text-align: center;
      justify-content: center;
      width: 129px;
      height: 36px;
      padding: 12px 18px;
      align-items: center;
      margin-left: 20px;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .m-body {
    padding: 25px 60px;

    @media screen and (max-width: 767px) {
      padding: 25px 20px;
    }

    .create-card {
      padding: 60px 45px 50px 45px;
      min-height: 480px;
      width: 100%;
      border-radius: 8px;
      border: 1px solid var(--line, #dee1e6);
      background: var(--White, #fff);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: 0.2s ease-in-out;
      align-items: center;
      cursor: pointer;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .svg {
          height: 120px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        svg {
          margin-bottom: 50px;
        }

        h3 {
          color: var(--On-Surface-Text-Colour, #202223);
          text-align: center;
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          /* 90% */
        }

        p {
          color: var(--On-Surface-Text-Subdued, #6d7175);
          text-align: center;
          font-family: Poppins;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px;
          /* 170% */
          max-width: 200px;
        }
      }

      .btn-outline {
        width: 80%;
        border-radius: 5px;
        border: 1px solid var(--line, #dee1e6) !important;
        background: var(--White, #fff) !important;
        display: flex;
        width: 237px;
        padding: 12px 18px;
        align-items: center;
        gap: 10px;
        color: var(--Primary-text, #2d2f31);
        text-align: center;

        /* Paragraph/P2/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
      }

      .btn-outline:hover {
        background: #2181fa !important;
        border: 1px solid #2181fa !important;
        color: white !important;
      }
    }

    .create-card:hover {
      border: 1px solid #9fcaff;
      background: var(--White, #fff);
      box-shadow: 0px 0px 20px 0px rgba(66, 134, 245, 0.3);
    }
  }
}

.switch-spaces {
  max-height: 335px !important;
  height: 100%;

  .search-wrapper {
    input {
      height: 40px !important;
      margin-bottom: 15px;
    }
  }

  left: 46px;
  top: 0px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #dee1e6;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 5px 10px;
  gap: 10px;

  .dropdown-menu {
    max-height: 335px !important;
    height: 100%;

    .items-list {
      border-top: 1px solid #dee1e6;
      border-bottom: 1px solid #dee1e6;
      padding: 13px 0;
      overflow-y: auto;
      max-height: 200px;

      img {
        width: 36px;
        height: 36px;
        border-radius: 50px;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .dropdown-item {
        display: flex;
        gap: 12px;
        align-items: center;
        font-weight: 400;
        font-size: 14px;

        &:active,
        :hover {
          background: #edf4ff;
          color: #2d2f31;
          border-radius: 5px;
        }
      }
    }

    .create-space-btn {
      margin-top: 13px;

      a {
        display: flex;
        align-items: center;
        gap: 10px;

        .logo {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background: #fff;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }

  a,
  p {
    font-size: 14px !important;
    font-weight: 600;
    line-height: 23px;
    color: #2d2f31;
    font-family: poppins;
  }

  svg {
    padding-right: 10px;
  }
}

.switch-spaces .dropdown-menu .items-list .dropdown-item:hover {
  background-color: #edf4ff;
  color: #2181fa;
  border-radius: 6px;
}

// ---new Navbar---
.newnav {
  padding: 13px 14px 13px 14px;
  border-bottom: 1px solid #dee1e6;
  height: 64px;
}

.main-section {
  display: flex;
  align-items: center;
  gap: 10px;

  @media only screen and (max-width: 767px) {
    gap: 5px;
  }
}

.section-1 {
  .menu-icon {
    border-radius: 4px;
    border: 1px solid var(--line, #dee1e6);
    background: #fff;
    margin: 0px;
  }

  .dropdown {
    button {
      background: transparent !important;
      border: none;
      padding: 0px;
      color: var(--Primary-text, #2d2f31);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 165%;
      /* 23.1px */
    }

    .dropdown-menu {
      border-radius: 8px;
      border: 1px solid var(--Borders-Neutral, #dee1e6);
      background: #fff;
      box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.1);
      inset: 17px auto auto 0px !important;
      padding: 0px;
    }

    .menuItems {
      padding: 20px 20px 10px 10px;
      width: 360px;

      .heading-wrapper {
        line-height: 36px;
        padding-bottom: 13px;
        margin-left: 10px;
        border-bottom: 1px solid #dee1e6;

        strong {
          color: var(--Font-Colour, #212121);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
        }
      }

      .mainItems {
        margin-top: 13px;

        .items {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 4px;
          border-radius: 5px;

          p {
            color: var(--Primary-text, #2d2f31);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.56px;
            margin: 0px;
          }

          a,
          span {
            color: var(--Primary-text, #2d2f31);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.56px;
          }
        }

        .items:hover {
          border-radius: 5px;
          background: var(--Brand-Hover, #edf4ff);

          p,
          a,
          span {
            color: var(--Primary-text, #2181fa);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.56px;
          }
        }
      }
    }
  }
}

// .section-2 {
//   display: flex;
//   align-items: center;
//   gap: 8px;
//   border-radius: 4px;
//   border: 1px solid var(--line, #dee1e6);
//   background: #fff;
//   padding: 5px 10px;
// }

.dropdown-sec {
  .dropdown {
    border-radius: 4px;
    border: 1px solid var(--line, #dee1e6);
    background: #fff;

    .create-btn {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 6px 10px;
      font-size: 12px;
      line-height: normal;

      @media only screen and (max-width: 767px) {
        padding: 6px 8px;
        gap: 3px;

        > img,
        svg {
          width: 18px;
          height: 18px;
          object-fit: contain;
        }
      }
    }

    .dropdown-menu {
      padding: 0px;
      inset: 23px auto auto 0px !important;
    }

    .menuItems {
      .dropdown-heading {
        display: flex;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid #dee1e6;
        padding-bottom: 13px;

        strong {
          color: var(--text-icons-primary, #2d2f31);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
      }

      .mainItems {
        padding-bottom: 13px;

        p {
          margin: 0px;
        }

        .item {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 14px;
          padding: 8px 10px;
          margin-bottom: 2px;
          cursor: pointer;

          img {
            border: none;
            box-shadow: 0px 0px 0px;
          }

          p {
            color: var(--Primary-text, #2d2f31);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.56px;
            margin: 0px;
          }
        }
      }

      .createSpace {
        img {
          border-radius: 37px;
          background: #fff;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
          width: 36px;
          height: 36px;
          padding: 6px;
        }

        display: flex;
        align-items: center;
        gap: 10px;
        border-top: 1px solid #dee1e6;
        padding-top: 13px;

        p {
          color: var(--Font-Colour, #212121);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin: 0px;
        }
      }
    }

    button {
      background: transparent !important;
      border: none;
      padding: 0px;
      color: var(--Primary-text, #2d2f31);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 165%;
    }

    button:hover {
      // background: transparent !important;
      // border: none;
      // padding: 0px;
      color: var(--Primary-text, #2d2f31);
    }
  }
}

.icons-section {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;

  @media only screen and (max-width: 767px) {
    gap: 5px;
  }
}

.starIcon {
  border-radius: 4px;
  border: 1px solid var(--line, #dee1e6);
  background: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    width: 30px;
    height: 30px;
  }

  img,
  svg {
    width: 20px;
    height: 20px;

    @media only screen and (max-width: 767px) {
      width: 15px;
      height: 15px;
    }
  }
}

.avtar {
  position: relative;
  width: 36px;
  height: 36px;

  @media only screen and (max-width: 767px) {
    width: 30px;
    height: 30px;
  }

  button {
    img,
    svg {
      width: 36px !important;
      height: 36px !important;

      @media only screen and (max-width: 767px) {
        width: 30px !important;
        height: 30px !important;
      }
    }
  }

  .dot-icon {
    position: absolute;
    top: 5%;
    right: 0px;
    font-size: 0;
    line-height: 0;

    img,
    svg {
      width: 9px;
      height: 9px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.dropdown {
  button {
    background: transparent !important;
    border: none;
    padding: 0px;
    color: var(--Primary-text, #2d2f31);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 165%;

    &.btn {
      &:hover {
        background-color: inherit !important;
        color: inherit !important;
        box-shadow: none !important;
      }

      &.show {
        color: inherit !important;
      }
    }
  }

  .dropdown-menu-avtar {
    border-radius: 8px;
    border: 1px solid var(--Borders-Neutral, #dee1e6);
    background: #fff;
    box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.1);
    padding: 0px;
    margin-top: 26px;
    max-height: 300px;
    overflow-y: auto;
  }

  ::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }

  .menuItems {
    padding: 20px;
    width: 360px;

    .dropdown-heading {
      display: flex;
      align-items: center;
      gap: 10px;
      border-bottom: 1px solid #dee1e6;
      padding-bottom: 13px;

      strong {
        color: var(--text-icons-primary, #2d2f31);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .mainItems {
      margin-top: 13px;

      // padding: 6px 10px;
      .items {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 4px;
        padding: 6px 10px;
        border-radius: 5px;

        &:hover {
          color: var(--Brand-Primary, #2181fa);
          background: var(--Brand-Hover, #edf4ff);

          img,
          svg {
            color: var(--Brand-Primary, #2181fa) !important;
          }

          p,
          span,
          a {
            color: var(--Brand-Primary, #2181fa);
          }
        }

        .icons-1 {
          border-radius: 37px;
          background: #fff;
          padding: 6px;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);

          svg {
            width: 22px;
            height: 22px;
          }
        }

        .icons {
          border-radius: 50px;
          background: #fff;
          width: 36px;
          height: 36px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);

          svg {
            width: 20px;
            height: 20px;
            color: #2d2f31;
          }
        }

        p {
          color: var(--Primary-text, #2d2f31);
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.56px;
          margin: 0px;
          cursor: pointer;
        }

        span {
          color: var(--Brand-Primary, #2181fa);
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.56px;
          cursor: pointer;
        }

        a {
          color: var(--Primary-text, #2d2f31);
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.56px;
          margin: 0px;
          cursor: pointer;
        }
      }
    }

    .notificationItmes {
      margin-top: 13px;

      // padding: 6px 10px;
      .items {
        gap: 10px;
        margin-bottom: 4px;
        padding: 6px 10px;
        border-radius: 5px;
        border-bottom: 1px solid #dee1e6;

        &:hover {
          color: var(--Brand-Primary, #2181fa);
          background: var(--Brand-Hover, #edf4ff);

          img,
          svg {
            color: var(--Brand-Primary, #2181fa) !important;
          }

          p,
          span,
          a {
            color: var(--Brand-Primary, #2181fa);
          }
        }

        .icons-1 {
          border-radius: 37px;
          background: #fff;
          padding: 6px;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);

          svg {
            width: 22px;
            height: 22px;
          }
        }

        .icons {
          border-radius: 50px;
          background: #fff;
          width: 36px;
          height: 36px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);

          svg {
            width: 20px;
            height: 20px;
            color: #2d2f31;
          }
        }

        p {
          color: var(--Primary-text, #2d2f31);
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.56px;
          margin: 0px;
          cursor: pointer;
        }

        span {
          color: var(--Brand-Primary, #2181fa);
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.56px;
          cursor: pointer;
        }

        a {
          color: var(--Primary-text, #2d2f31);
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.56px;
          margin: 0px;
          cursor: pointer;
        }
      }
    }

    .logout-btn:hover {
      color: var(--Brand-Primary, #2181fa);
    }

    .logout-btn {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-top: 13px;
      border-top: 1px solid #dee1e6;
      margin-left: 10px;

      .icons {
        border-radius: 37px;
        background: #fff;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;

        svg {
          width: 18px;
          height: 18px;
        }

        svg:hover {
          color: var(--Brand-Primary, #2181fa);
        }
      }

      p {
        color: var(--Font-Colour, #212121);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0px;
        cursor: pointer;
      }

      p:hover {
        color: var(--Brand-Primary, #2181fa);
      }
    }
  }

  .dot-icon {
    position: absolute;
    top: -8px;
    left: 28px;
  }
}

.spaceModal {
  .modal-header {
    padding: 12px 12px 12px 20px;

    strong {
      color: var(--Primary-text, #2d2f31);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: -0.28px;
    }
  }

  .modal-body {
    .space-image {
      text-align: center;
      margin-top: 28px;
    }

    .spaceInput {
      text-align: center;
      margin: 24px 0px 28px 0px;

      input {
        width: 320px;
        padding: 16px 12px;
        color: var(--On-Surface-Text-Subdued, #6d7175);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        border-radius: 6px;
        border: 1px solid var(--Main-Color-Secondary, #ccc);
        background: var(--Surface-White, #fff);
      }
    }
  }

  .modal-footer {
    .outline-butn {
      border-radius: 4px;
      border: 1px solid #006bf1;
      background: var(--White, #fff);
      padding: 3px 13px;
      color: #006bf1;
      text-align: center;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }

    .btn-1 {
      padding: 3px 13px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .section-1 {
    .dropdown {
      .menuItems {
        width: 250px;
        margin-right: 10px;
      }
    }
  }

  .section-2 {
    .dropdown {
      .menuItems {
        width: 240px;
      }
    }
  }

  .dropdown {
    .menuItems {
      width: 240px;
    }
  }
}

//-----All Products-----
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.page-title {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 40px;

  img {
    width: 39.999px;
    height: 40px;
  }

  p {
    color: var(--Primary-text, #2d2f31);
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.64px;
    margin: 0px;
  }
}

.people-section {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid var(--G1, #eb00ff);
  height: 42px;
  margin-top: 40px;

  img {
    width: 24px;
    height: 24px;
  }

  p {
    color: var(--On-Surface-Text-Colour, #202223);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0px;
  }
}

.table-wrapper {
  margin: 10px 0px;

  table {
    border-collapse: separate;
    border-spacing: 0 15px;

    thead {
      tr {
        th {
          border: none !important;
          padding: 10px 10px 10px 0px;
          color: var(--Primary-text, #2d2f31);
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    tbody {
      tr {
        border-radius: 8px;
        border: 1px solid var(--line, #dee1e6);
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
        padding-right: 20px;

        td {
          padding-right: 20px;

          .image-wrapper {
            display: flex;
            padding: 10px;
            align-items: center;
            gap: 10px;

            img {
              padding: 9px;
              border-radius: 100px;
              background: #fff;
              box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
            }

            p {
              color: var(--On-Surface-Text-Colour, #202223);
              font-family: Poppins;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
              margin: 0px;
            }

            p:hover {
              color: #2181fa;
              cursor: pointer;
            }
          }

          p {
            margin: 0 0 0 0;
          }
        }
      }
    }
  }
}

.dropdown-sec .dropdown .menuItems .mainItems .item:hover {
  color: #2181fa;
  background-color: #edf4ff;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

// nodatafound css
.access-denied-page {
  display: flex;
  justify-content: center;

  // padding: 90px 10px;
  .access-page-wrapper {
    // border-radius: 10px;
    // background: #FFF;
    // box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);

    .lock-img {
      text-align: center;
      padding: 64px;

      svg,
      img {
        width: 64px;
        height: 68px;
        object-fit: contain;
        color: #2181fa;
      }

      h2 {
        color: #2d2f31;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -1.28px;
        padding: 18px;
      }

      p {
        color: var(--text-2, #a29e9e);
        text-align: center;
        font-size: 11px;
        font-style: normal;
        font-weight: 300;
      }

      button {
        width: 340px;
        padding: 16px 38px;
        border-radius: 7px;
        background: var(--Main-Color-Main-Color, #2181fa);
        border: none;
        color: white;
      }
    }

    .footer-wrapper {
      height: 90px;
      border-radius: 0px 0px 7px 7px;
      background: #fae0e0;

      .img-inner {
        display: flex;
        align-items: center;
        padding: 24px 26px;
        gap: 12px;

        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }

        p {
          color: var(--Primary-text, #2d2f31);
          font-size: 20px;
          font-weight: 400;
        }
      }
    }
  }
}

.access-denied-page {
  display: flex;
  justify-content: center;
  padding: 90px 10px;

  .access-page-wrapper-main {
    // width: 35%;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  }

  .lock-img {
    text-align: center;
    padding: 64px;

    img {
      width: 330px;
      height: 330px;
      object-fit: contain;
    }

    h2 {
      color: #2d2f31;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      // line-height: 46px;
    }

    button {
      width: 300px;
      padding: 16px 38px;
      border-radius: 7px;
      background: var(--Main-Color-Main-Color, #2181fa);
      border: none;
      color: white;
    }
  }

  .footer-wrapper {
    height: 95px;
    border-radius: 0px 0px 7px 7px;
    background: #fae0e0;

    .img-inner {
      display: flex;
      align-items: center;
      padding: 28px 48px;
      gap: 12px;

      img {
        width: 30px;
        height: 30px;
        object-fit: contain;
      }

      p {
        color: var(--Primary-text, #2d2f31);
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .access-denied-page {
    .lock-img {
      button {
        width: 225px !important;
      }
    }
  }
}

.mainItems {
  max-height: 300px;
  overflow-y: auto;
  margin-top: 10px;
}

.mainItems::-webkit-scrollbar {
  width: 4px;
}

.mainItems::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.mainItems::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.mainItems::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.mainItems {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

//------------------new design consol--------//
$font: "Poppins", serif;
$pcolor: rgba(158, 159, 162, 1);
$hcolor: rgba(45, 47, 49, 1);
$white: #dee1e6;

.searchbar {
  margin-top: 10px;
  margin-bottom: 10px;

  .text {
    padding: 8px 10px;
    border: 1px solid rgba(222, 225, 230, 1);
    border-radius: 5px;
    display: flex;
    align-items: center;

    .filtericon {
      height: 16px;
      width: 16px;
      margin-left: 10px;
      margin-right: 14px;
    }

    span,
    input {
      font-family: $font;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      color: rgba(162, 158, 158, 1);
      width: 100%;
    }
  }

  .text2 {
    align-self: center;

    span {
      font-family: $font;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: black;
      margin-right: 47px;
    }

    .blueclock {
      margin-right: 10px;
      margin-left: 10px;
      width: 20px;
      height: 20px;
    }

    #dropdown-basic {
      background-color: transparent;
      padding: 8px 10px;
      box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.87),
        0 3px 5px 0 rgb(226, 223, 223);
      border: 1px solid rgba(222, 225, 230, 1);
      border-radius: 5px;
      color: black;
    }
  }
}

.consol-dashboard {
  padding: 40px 72px;

  .consol-header {
    border: 1px solid rgba(222, 225, 230, 1);
    border-radius: 10px;

    .info1 {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-left: 66px;
      margin-bottom: 32px;
      margin-top: 32px;

      .text1 {
        h5 {
          font-family: $font;
          font-size: 32px;
          font-weight: 600;
          line-height: 40px;
          text-align: left;
        }
      }

      .text2 {
        p {
          font-family: $font;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          color: rgba(45, 47, 49, 1);

          span {
            font-family: $font;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            color: rgba(33, 129, 250, 1);
          }
        }
      }

      .proimg {
        .green-rounded-img {
          height: 150px;
          width: 150px;
          object-fit: contain;
          border-radius: 50%;
          border: 6px solid #00b568;
        }
      }
    }

    .icons {
      display: flex;
      gap: 40px;
      justify-content: end;
      align-items: center;
      height: 100%;
      margin-right: 40px;

      .icon1 {
        span {
          font-family: $font;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
        }

        .iconimg {
          height: 24px;
          width: 24px;
          object-fit: contain;
          margin-right: 12px;
        }
      }

      .icon2 {
        display: flex;
        border: 1px solid rgba(222, 225, 230, 1);
        border-radius: 10px;
        padding: 20px;

        .capimg {
          margin-right: 14px;
          border: 1px solid rgba(222, 225, 230, 1);
          box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.87),
            0 3px 5px 0 rgb(226, 223, 223);
          border-radius: 24px;
          width: 52px;
          height: 52px;
          display: flex;
          justify-content: center;
          align-items: center;

          .green-circle {
            height: 52px;
            width: 52px;
            object-fit: contain;
          }
        }

        .inico2 {
          display: flex;
          align-items: center;

          h5 {
            font-family: $font;
            margin-bottom: 0;
            margin-right: 14px;

            font-size: 48px;
            font-weight: 600;
            line-height: 40px;
            text-align: left;
          }
        }
      }
    }
  }

  .consol-links {
    margin-top: 40px;
    margin-bottom: 20px;

    .tags {
      margin-bottom: 20px;
      list-style: none;
      display: flex;
      // margin-bottom: 0;
      border-bottom: 1px solid rgba(222, 225, 230, 1);

      li {
        position: relative;

        &:not(:last-of-type) {
          margin: 0 40px 0 0;

          &:after {
            content: "";
            display: inline-block;
            width: 1px;
            background: rgba(222, 225, 230, 1);
            height: 20px;
            position: absolute;
            right: -20px;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        padding: 0;

        &:first-of-type a {
          border: none;
        }

        a.active {
          text-decoration: none;
          color: rgba(33, 129, 250, 1) !important;
          font-family: $font;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          text-align: left;
          border-bottom: 1px solid rgba(33, 129, 250, 1);
        }

        a {
          text-decoration: none;
          color: $hcolor;
          font-family: $font;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          padding: 22px 0;
          display: inline-block;
          // &:not(&:last-of-type)::after{
          //   content: '';
          //   display: inline-block;
          //   border-left: 1px solid rgba(222, 225, 230, 1);
          //   margin: 0 20px;

          // }
        }
      }
    }
  }

  .consol-card-heading {
    h2 {
      border-bottom: 1px solid rgba(222, 225, 230, 1);
      padding-bottom: 20px;
      font-family: $font;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      margin-bottom: 0;
    }

    .consol-whole-cards {
      margin-top: 20px;

      .card {
        flex-direction: row;
        padding: 20px;
        gap: 14px;
        border-radius: 10px;
        border: 1px solid rgba(222, 225, 230, 1);

        .cardtext {
          h5 {
            margin-bottom: 0;
            font-family: $font;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            text-align: left;
          }

          p {
            margin-bottom: 0;
            font-family: $font;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: rgba(158, 159, 162, 1);
          }
        }

        .cardimg {
          margin-right: 14px;
          margin-left: 20px;
          border: 1px solid rgba(222, 225, 230, 1);
          box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.87),
            0 3px 5px 0 rgb(226, 223, 223);
          border-radius: 24px;
          width: 52px;
          height: 52px;
          display: flex;
          justify-content: center;
          align-items: center;

          .imgcard {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }

  .consol-dashboard-cards {
    .card-body {
      text-align: center;
      border: 1px solid rgba(222, 225, 230, 1);
      box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.87),
        0 3px 5px 0 rgb(226, 223, 223);
      border-radius: 10px;
      margin-top: 20px;

      .icon {
        justify-self: center;
        margin-bottom: 14px;
        margin-top: 49px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 52px;
        border: 1px solid rgba(222, 225, 230, 1);
        box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.87),
          0 3px 5px 0 rgb(226, 223, 223);
        border-radius: 159px;

        .card-icon {
          width: 32px;
          height: 32px;
          object-fit: contain;
        }
      }

      .card-text {
        h5 {
          font-family: $font;
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          text-align: center;
          color: rgba(45, 47, 49, 1);
        }

        p {
          font-family: $font;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
          color: rgba(158, 159, 162, 1);
          margin-bottom: 49px;
        }
      }
    }
  }
}

.consol-table-page {
  .consol-table {
    .table-of-consol {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 20px;

      thead {
        tr {
          th {
            &:last-of-type {
              text-align: right;
            }

            .info {
              span {
                font-family: $font;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
              }

              .arrow {
                margin-left: 10px;
              }
            }

            .info3 {
              .arrow {
                margin-left: 10px;
              }

              text-align: right;

              span {
                font-family: $font;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
              }
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &:last-of-type {
              text-align: right;
            }

            .info2 {
              span {
                background-color: rgba(237, 244, 255, 1);
                padding: 4px 33px;
                border-radius: 7px;
                color: rgba(33, 129, 250, 1);
                font-family: $font;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
              }
            }

            .info3 {
              span {
                background-color: rgba(229, 255, 234, 1);
                padding: 4px 33px;
                border-radius: 7px;
                color: rgba(34, 197, 94, 1);
                font-family: $font;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
              }
            }

            .info4 {
              span {
                background-color: rgba(253, 241, 245, 1);
                padding: 4px 33px;
                border-radius: 7px;
                color: rgba(239, 68, 68, 1);
                font-family: $font;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
              }
            }

            .images {
              .crownicon {
                width: 26.67px;
                height: 16.85px;
              }
            }

            .image2 {
              text-align: right;

              .checkicon {
                height: 20px;
                width: 20px;
              }
            }

            border-top: 1px solid rgba(222, 225, 230, 1);
            border-bottom: 1px solid rgba(222, 225, 230, 1);

            padding: 14px;

            &:first-of-type {
              border-left: 1px solid rgba(222, 225, 230, 1);
              border-radius: 8px 0 0 8px;
            }

            &:last-of-type {
              border-right: 1px solid rgba(222, 225, 230, 1);
              border-radius: 0 8px 8px 0;
            }

            .infoa {
              display: flex;
              align-items: center;
              gap: 20px;

              .infoimg {
                width: 36px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid rgba(222, 225, 230, 1);
                box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.87),
                  0 3px 5px 0 rgb(226, 223, 223);
                border-radius: 0.9px;
                padding: 7.2px;
                border-radius: 18.67px;

                .tableimg {
                  height: 16px;
                  width: 16px;
                  object-fit: cover;
                }
              }

              span {
                font-family: $font;
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
                text-align: left;
              }
            }
          }
        }
      }
    }

    .table2-of-consol {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 20px;

      thead {
        tr {
          th {
            &:last-of-type {
              text-align: right;
            }

            .info {
              span {
                font-family: $font;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
              }

              .arrow {
                margin-left: 10px;
              }
            }

            .info1 {
              text-align: right;
              &.info-space{
                margin-right: 89px;
              }

              span {
                font-family: $font;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
              }
            }

            .info3 {
              .arrow {
                margin-left: 10px;
              }

              text-align: right;

              span {
                font-family: $font;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
              }
            }
          }
        }
      }

      tbody {
        tr {
          box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.87),
            0 3px 5px 0 rgb(226, 223, 223);

          td {
            border-top: 1px solid rgba(222, 225, 230, 1);
            border-bottom: 1px solid rgba(222, 225, 230, 1);
            padding: 14px;

            &:last-of-type {
              text-align: right;
            }

            .info2 {
              text-align: right;

              .icon {
                width: 24px;
                height: 24px;
                object-fit: contain;
              }

              span {
                font-family: $font;
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.01em;
                color: rgba(32, 34, 35, 1);
              }
            }

            &:first-of-type {
              border-left: 1px solid rgba(222, 225, 230, 1);

              border-radius: 8px 0 0 8px;
            }

            &:last-of-type {
              border-right: 1px solid rgba(222, 225, 230, 1);

              border-radius: 0 8px 8px 0;
            }

            .infoa {
              display: flex;
              align-items: center;
              gap: 20px;

              .infoimg {
                width: 36px;
                height: 36px;

                .tableimg {
                  height: 36px;
                  width: 36px;
                  object-fit: contain;
                }
              }

              span {
                font-family: $font;
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
                color: rgba(32, 34, 35, 1);
              }
            }
          }
        }
      }
    }
  }
}

.consol-application-page {
  .consol-applications {
    .whole-info {
      margin-bottom: 40px;

      .heading {
        display: flex;
        align-items: center;
        gap: 10px;

        .icon {
          height: 32px;
          width: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 3.48px;
          box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.87),
            0 3px 5px 0 rgb(226, 223, 223);

          .hedding-icon {
            width: 21.99px;
            height: 22.03px;
            object-fit: contain;
          }
        }

        span {
          font-family: $font;
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
        }
      }

      .text {
        display: flex;
        justify-content: space-between;
        padding: 10px 13px;
        margin-bottom: 10px;

        span {
          font-family: $font;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
        }
      }

      .info {
        display: flex;
        justify-content: space-between;
        padding: 14px;
        box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.87),
          0 3px 5px 0 rgb(226, 223, 223);
        border: 1px solid rgba(222, 225, 230, 1);
        border-radius: 8px;
        margin-bottom: 10px;

        .info1 {
          display: flex;
          align-items: center;
          gap: 10px;

          span {
            font-family: $font;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
          }

          .icon {
            height: 36px;
            width: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            box-shadow: 0 4px 8px 0 rgba(240, 239, 239, 0.87),
              0 3px 5px 0 rgb(226, 223, 223);

            #icon {
              width: 24px;
              height: 24px;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

//--------------tabs-design---------//

.card-div {
  background-color: #ffffff;
  border: 1px solid var(--Borders-Stork, #dee1e6);
  box-shadow: 0px 1px 3px 0px #0000000f;
  border-radius: 10px;
  height: 100%;
}

//---------table----------//
.main-table {
  overflow-y: auto;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 20px;
    background-color: transparent;

    thead {
      tr {
        background: transparent;

        th {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: var(--Primary-text, #2d2f31);
          font-family: "Poppins", sans-serif;
          padding: 0 0 0 20px;
          background: transparent;
          border: none;
        }
      }
    }

    tbody {
      tr {
        background: transparent;

        td {
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          color: #202223;
          font-family: "Poppins", sans-serif;
          padding: 12px 14px;
          border-top: 1px solid #dee1e6;
          border-bottom: 1px solid #dee1e6;
          border-right: none;
          border-left: none;
          background: transparent;

          &:last-of-type {
            border-right: 1px solid #dee1e6;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }

          &:first-of-type {
            border-left: 1px solid #dee1e6;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }

          p {
            margin: 0 0 0 0;
          }

          .qur {
            display: flex;
            align-items: center;
            gap: 8px;

            .img-wrap {
              border-radius: 50%;
              height: 36px;
              width: 36px;
              display: flex;
              align-items: center;
              justify-content: center;
              box-shadow: 0px 1px 3px 0px #00000040;

              img {
                height: 24px;
                width: 24px;
                object-fit: contain;
              }
            }

            h1,
            h3 {
              font-size: 20px !important;
              margin: 0 0 0;
            }
          }

          .main-batch {
            background-color: #ffffff;
            box-shadow: 0px 1px 3px 0px #00000040;
            border-radius: 50%;
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 24px;
              height: 24px;
              object-fit: contain;
            }
          }

          .batch {
            display: flex;
            align-items: center;
            gap: 10px;

            img {
              width: 100%;
              max-width: 36px;
            }

            p {
              font-weight: 600;
              font-size: 20px;

              color: #202223;
            }
          }
        }
      }
    }
  }
}

.activity-page {
  margin: 20px 0 0 0;

  h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: $black;

    @media screen and (max-width: 786px) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  p {
    font-size: 14px;
    line-height: 24px;
    color: #9e9fa2;
  }

  .student-activity-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 20px;
    border-bottom: 1px solid #dee1e6;

    .dropdown {
      .dropdown-toggle {
        background-color: transparent;
        border: 1px solid #dee1e6;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        color: $black;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        gap: 4px;
        align-items: center;
      }
    }

    .dropdown-toggle::after {
      display: none !important;
    }

    button#dropdown-basic {
      padding: 2px 16px 2px 20px;
      justify-content: start;
    }
  }

  .card-div {
    .main-table {
      padding-inline: 15px;
    }
  }

  .student-activity {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid #dee1e6;

    img,
    svg {
      color: #2d2f31;
    }
  }

  .main-chart {
    margin: 49px 14px 0 14px;
    height: 260px;
  }

  .bar-chart {
    width: 100% !important;
  }

  .mastery {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    text-align: center;
    padding: 69px 60px;

    @media screen and (max-width: 575px) {
      display: grid;
      grid-template-columns: auto auto;
      padding: 20px;
    }

    .main-crown {
      .crown {
        border: 0.8px solid #dee1e6;
        box-shadow: 0px 0.8px 2.4px 0px #0000000f;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 575px) {
          width: 55px;
          height: 55px;
          margin: auto;
        }

        img {
          width: 100%;
          max-width: 46px;

          @media screen and (max-width: 575px) {
            max-width: 32px;
          }
        }
      }

      h4 {
        margin: 20px 0 0 0;

        @media screen and (max-width: 575px) {
          font-size: 14px;
          line-height: 22px;
        }
      }

      p {
        padding: 6px 0 0 0;
        margin: 0px;

        @media screen and (max-width: 575px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}

.tabs-main-wrapper {
  border-bottom: 1px solid rgba(222, 225, 230, 1);

  .links {
    margin-bottom: 0;

    ul {
      list-style: none;
      display: flex;
      gap: 40px;
      padding: 0;
      margin-bottom: 0;
      overflow-x: auto;

      @media screen and (max-width: 767px) {
        justify-content: space-between;
        gap: 5px;
      }

      li {
        a {
          text-decoration: none;
          color: #2d2f31;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          display: block;
          position: relative;
          padding: 22px 0;
          border: none;

          @media screen and (max-width: 767px) {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            padding: 16px 0;
          }

          &:after {
            content: "";
            display: block;
            position: absolute;
            right: -20px;
            height: 19px;
            width: 1px;
            background: #dee1e6;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 4px;

            @media screen and (max-width: 767px) {
              right: -42%;
            }
          }

          &.active {
            border-bottom: 1px solid #2181fa;
            font-weight: 600 !important;
            color: #2181fa !important;
          }
        }

        &:last-child a:after {
          content: none;
        }
      }
    }
  }
}

// consol-dashboard

.class-bar-section {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #dee1e6;
  border-radius: 4px;
  gap: 8px;
  cursor: pointer;

  svg,
  img {
    width: 24px;
    height: 24px;
  }

  p {
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 23.1px;
    color: #2d2f31;
  }

  span {
    svg {
      color: #9e9fa2;
    }
  }
}

.assets-table-body {
  width: 100%;
  // padding: 12px 25px;
  padding: 14px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid var(--line, #dee1e6);
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  .table-data:last-child {
    justify-content: flex-end;
  }

  .table-row {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .table-data {
      width: 100%;
      display: flex;
      align-items: center;
      white-space: nowrap;
      // overflow: hidden;
      text-overflow: ellipsis;

      .dropdown-toggle::after {
        display: none;
      }

      .fa-star {
        color: #dee1e6;
        font-size: 18px;
      }

      .symbol svg {
        width: 36px;
        height: 36px;
        flex-shrink: 0;
        fill: var(--White, #fff);
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
      }

      img {
        height: 36px;
        width: 36px;
        border-radius: 4px;
        margin-right: 10px;
      }

      h3 {
        margin-bottom: 0px;
        color: var(--On-Surface-Text-Colour, #202223);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px !important;
        width: 100%;
      }

      p {
        margin-bottom: 0px;
        color: var(--On-Surface-Text-Colour, #202223);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;

        svg {
          margin-right: 8px;
        }
      }
    }
  }
}

////........highlight page.....////

.highlight-header {
  padding: 0px 0px 24px;
  border-bottom: 1px solid #dee1e6;
  margin-bottom: 20px;
  h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    columns: #2d2f31;
  }
}

// activity page
.activity-page {
  margin: 20px 0 0 0;
  svg {
    width: 24px;
    height: 24px;
  }

  h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    margin: 0 !important;
    color: $black !important;

    @media screen and (max-width: 768px) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  p {
    font-size: 14px;
    line-height: 24px;
    color: #9e9fa2;
  }

  .student-activity-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 12px 20px;
    padding: 9px 20px;
    border-bottom: 1px solid #dee1e6;

    .dropdown {
      .dropdown-toggle {
        background-color: transparent;
        border: 1px solid #dee1e6;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        color: $black;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        gap: 4px;
        align-items: center;
      }
      .dropdown-menu {
        min-width: 100px;
        width: 100%;
        top: 5px;
        .dropdown-item {
          font-weight: 400;
          line-height: 21px;
          font-size: 14px !important;
          color: rgb(45, 47, 49);
        }
      }
    }

    .dropdown-toggle::after {
      display: none !important;
    }

    button#dropdown-basic {
      // padding: 2px 16px 2px 20px;
      padding: 3px 10px;

      @media screen and (max-width: 576px) {
        padding: 2px 6px 2px 11px;
      }
    }
  }

  .card-div {
    .main-table {
      padding-inline: 15px;
    }
  }

  .student-activity {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 12px 20px;
    padding: 12px 14px;
    border-bottom: 1px solid #dee1e6;
    @media screen and (max-width: 576px) {
      padding: 10px;
    }
  }

  .main-chart {
    margin: 49px 14px 0 14px;
    height: 260px;
    @media screen and (max-width: 576px) {
      height: 200px;
    }
  }

  .bar-chart {
    width: 100% !important;
  }

  .mastery {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    text-align: center;
    padding: 69px 60px;

    @media screen and (max-width: 1199px) {
      display: grid;
      grid-template-columns: auto auto;
      padding: 20px 55px;
    }
    @media screen and (max-width: 576px) {
      padding: 20px 30px;
    }

    .main-crown {
      .crown {
        border: 0.8px solid #dee1e6;
        box-shadow: 0px 0.8px 2.4px 0px #0000000f;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          width: 70px;
          height: 70px;
        }
        @media screen and (max-width: 767px) {
          width: 55px;
          height: 55px;
          margin: auto;
        }

        @media screen and (max-width: 991px) {
          width: 62px;
          height: 62px;
          margin: auto;
        }

        img {
          width: 46.4px;
          height: 29.31px;
          object-fit: contain;
          @media screen and (max-width: 1199px) and (min-width: 992px) {
            width: 35px;
            height: 20px;
          }
          @media screen and (max-width: 767px) {
            width: 32px;
            height: 32px;
          }
          @media screen and (max-width: 991px) {
            width: 40px;
            height: 20px;
          }
        }
      }

      h4 {
        margin: 20px 0 0 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: rgba(45, 47, 49, 1);

        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 22px;
        }
      }

      p {
        padding: 6px 0 0 0;
        margin: 0px;
        color: rgba(158, 159, 162, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        @media screen and (max-width: 767px) {
          font-size: 12px !important;
          line-height: 18px;
        }
        @media screen and (max-width: 991px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .trophy-crown {
      .crown {
        border: 0.8px solid #dee1e6;
        box-shadow: 0px 0.8px 2.4px 0px #0000000f;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 767px) {
          width: 55px;
          height: 55px;
          margin: auto;
        }
        @media screen and (max-width: 991px) {
          width: 62px;
          height: 62px;
          margin: auto;
        }

        img {
          // width: 46px;
          // height: 46px;
          width: 35.78px;
          height: 35.78px;
          object-fit: contain;

          @media screen and (max-width: 767px) {
            width: 32px;
            height: 32px;
          }
          @media screen and (max-width: 991px) {
            width: 24px;
            height: 24px;
          }
        }
      }

      h4 {
        margin: 20px 0 0 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: rgba(45, 47, 49, 1);

        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 22px;
        }
      }

      p {
        padding: 6px 0 0 0;
        margin: 0px;
        color: rgba(158, 159, 162, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        @media screen and (max-width: 767px) {
          font-size: 12px !important;
          line-height: 18px;
        }
        @media screen and (max-width: 991px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}

// question column
.question-coloumn {
  h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin: 0px;
    color: #000000;
    text-align: left;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    color: #9e9fa2;
    margin: 0 0 0;
  }

  .ques {
    display: flex;
    align-items: center;
    padding: 21px 0 21px 20px;
    gap: 14px;
    .user {
      width: 52px;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 1.42px 4.25px 0px rgba(0, 0, 0, 0.2509803922);
      background-color: #ffffff;
      border-radius: 50%;
      // svg,
      // img {
      //   width: 34px;
      //   height: 34px;
      //   object-fit: contain;
      // }
      svg,
      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
    }
  }
  .card-div {
    height: 100% !important;
    background-color: #ffffff;
    border: 1px solid var(--Borders-Stork, #dee1e6);
    box-shadow: 0px 1px 3px 0px #0000000f;
    border-radius: 10px;
    // margin-bottom: 20px;
    text-align: center;

    &.card-bottom-space {
      margin-bottom: 0px;
      @media screen and (max-width: 767px) {
        margin-bottom: 0px !important;
      }
    }

    @media screen and (max-width: 767px) {
      height: auto;
      margin-bottom: 12px !important;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

// .products-container{
//   .home-section{
//     display: flex;
//     align-items: center;
//     gap: 10px;
//     .home-img{
//       // text-align: center;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       width: 32px;
//       height: 32px;
//       box-shadow: 0px 0.87px 2.61px 0px rgba(0, 0, 0, 0.25);
//       border-radius: 3.48px;
//       // padding: 7.83px;
//       img{
//         width: 21.99px;
//         height: 22px;
//       }
//     }
//     h2{
//       font-size: 20px;
//       font-weight: 600;
//       line-height: 24px;
//       color: #202223;
//       margin: 0px;

//     }

//   }

//   .table-wrapper {
//     // margin: 10px 0px;

//     table {
//       border-collapse: separate;
//       border-spacing: 0 15px;

//       thead {
//         tr {
//           th {
//             border: none !important;
//             padding: 10px 10px 10px 0px;
//             color: var(--Primary-text, #2d2f31);
//             font-family: Poppins;
//             font-size: 14px;
//             font-style: normal;
//             font-weight: 500;
//             line-height: 24px;
//           }
//         }
//       }

//       tbody {
//         tr {
//           border-radius: 8px;
//           border: 1px solid var(--line, #dee1e6);
//           box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
//           padding-right: 20px;

//           td {
//             padding-right: 20px;
//             .image-wrapper {
//               display: flex;
//               padding: 10px;
//               align-items: center;
//               gap: 10px;

//               img {
//                 padding: 9px;
//                 border-radius: 100px;
//                 background: #fff;
//                 box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
//               }

//               p {
//                 color: var(--On-Surface-Text-Colour, #202223);
//                 font-family: Poppins;
//                 font-size: 20px;
//                 font-style: normal;
//                 font-weight: 600;
//                 line-height: 24px;
//                 margin: 0px;
//               }
//               p:hover{
//                color: #2181FA;
//                cursor: pointer;
//               }
//             }

//             p {
//               margin: 0 0 0 0;
//             }
//           }
//         }
//       }
//     }
//   }

// }

.consol-application-page {
  .admin-dashboard-card-heading {
    padding-bottom: 24px;
    border-bottom: 1px solid #dee1e6;
    margin-bottom: 20px;
    h2 {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      columns: #2d2f31;
      margin: 0px;
    }
  }
  .consol-applications {
    .whole-info {
      margin-bottom: 40px;

      .heading {
        display: flex;
        align-items: center;
        gap: 10px;
        @media screen and (max-width: 668px) {
          gap: 4px;
        }

        .icon {
          height: 32px;
          width: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 3.48px;
          box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.87),
            0 3px 5px 0 rgb(226, 223, 223);

          .hedding-icon {
            width: 21.99px;
            height: 22.03px;
            object-fit: contain;
          }
        }

        span {
          font-family: $font;
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
          @media screen and (max-width: 668px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      .text {
        display: flex;
        justify-content: space-between;
        padding: 5px 13px 10px 13px;
        margin-bottom: 10px;

        span {
          font-family: $font;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
        }
      }

      .info {
        display: flex;
        justify-content: space-between;
        padding: 14px;
        box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.87),
          0 3px 5px 0 rgb(226, 223, 223);
        border: 1px solid rgba(222, 225, 230, 1);
        border-radius: 8px;
        margin-bottom: 10px;

        .info1 {
          display: flex;
          align-items: center;
          gap: 10px;

          span {
            font-family: $font;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            @media screen and (max-width: 668px) {
              font-size: 14px;
              line-height: 18px;
            }
          }

          .icon {
            height: 36px;
            width: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            box-shadow: 0 4px 8px 0 rgba(240, 239, 239, 0.87),
              0 3px 5px 0 rgb(226, 223, 223);
            @media screen and (max-width: 668px) {
              width: 30px;
              height: 30px;
            }
            #icon {
              width: 24px;
              height: 24px;
              object-fit: contain;
              @media screen and (max-width: 668px) {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }
}

// ...classs model.../
.space-listing-modal {
  ::-webkit-scrollbar-thumb {
    background: lightgray;
  }
  .modal-dialog{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;

  }
  .modal-content {
    min-height: 420px !important;
    .modal-header{
      padding: 12px 12px 12px 20px !important;
      .modal-title{
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
      }
      .btn-close{
        width: 24px;
        height: 24px;
      }
    }
    .modal-body {
      .class-modal-filter{
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0px 10px;
        border-radius: 4px;
        border: 1px solid #DEE1E6;
        box-shadow: 0px 1px 3px 0px #0000001A;
        gap: 4px;
        min-height: 40px !important;
        margin-bottom: 10px;
        input{
          border: none !important;
          outline: none !important;
          &::placeholder{
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            color: #A29E9E;
          }
        }
        svg,img{
          color: #9E9FA2;
          width: 24px;
          height: 24px;
          
  
        }
      }
      .main-screen-content-wrapper {
        .people-body {
          .people-content {
            max-height: calc(100vh - 450px);
            overflow: auto;
            padding-bottom: 40px;
            .people-header{
              padding: 2px 0px 2px 13px;
              h1{
                font-size: 14px !important;
                line-height: 24px !important;
                font-weight: 500 !important;
              }
            }
            .SetData-Btn {
              position: absolute;
              bottom: 0;
              margin-bottom: 0;
              right: 0;
              background: #ffffff;
              z-index: 2;
              width: 100%;
              height: auto !important;
              padding-right: 10px !important;
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }
        }
      }
    }
  }
}
