@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
$main: #2181fa;
$main-bg: linear-gradient(90deg, #e95567 0%, #f36735 100%);
$card-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

@media only screen and (max-width: 1300px) {
  .hero-section .content {
    padding-top: 200px;
    margin-left: 16%;
  }
}

@media only screen and (max-width: 991px) {
  .top-nav-section-outer {
    padding: 0px 30px !important;
  }

  .admin-body {
    padding: 20px 30px !important;
  }
}

@media only screen and (max-width: 767px) {
  // .invites-table,
  // .tags-row,
  // .Organizationn {
  //   width: 768px !important;

  // }
  .header-top-nav {
    overflow: visible !important;
  }

  .invites-table .table-header {
    display: none;
  }

  .invites-table .table-body .table-row .table-item .img-list {
    height: 40px !important;
    width: 40px !important;
    border-radius: 6px !important;
  }

  .invites-table .table-body .table-row .table-item p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .invites-table .table-body .table-row {
    padding: 5px 0px;
    flex-wrap: wrap;
    position: relative;

    .table-item {
      width: 50%;
      margin-bottom: 10px;
      justify-content: flex-start;
    }

    .table-item:first-child {
      width: 100%;
    }

    .table-item:last-child {
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .tags-row {
    position: relative;

    .g-header {
      display: none;
    }

    .g-row {
      flex-wrap: wrap;

      .colm {
        margin-top: 5px;
        width: 50% !important;
        justify-content: flex-start !important;
      }

      .colm:last-child {
        position: absolute;
        top: 10px;
        right: 15px;
      }
    }
  }

  .Organizationn {
    .all-org-header {
      display: none;
    }

    .all-org {
      flex-wrap: wrap;
      position: relative;

      .side {
        margin-top: 10px;
        justify-content: flex-start;
      }

      .side:last-child {
        top: 10px;
        right: 10px;
        position: absolute;
      }
    }
  }

  .dashboard-body .body-content h1 {
    font-size: 22px;

    span {
      font-size: 22px;
    }
  }

  .inner-brand .left {
    padding: 15px;
  }

  .inner-brand .left .mid-section .round-color {
    height: 40px !important;
    width: 40px !important;
  }

  .roles-body .roles-card .card-body {
    flex-direction: column;
  }

  .create-tag-bar {
    .main-header {
      padding: 20px !important;
    }
  }

  .offcanvas-body {
    .btn {
      font-size: 14px !important;
    }
  }

  .add-team-member {
    .add-team {
      padding: 20px;
    }
  }

  .about-this-role {
    .about {
      padding: 20px;
    }
  }

  .create-role {
    .create-roll {
      padding: 20px;
    }
  }

  .edit-team-member {

    .add-team,
    .right {
      padding: 20px;
    }
  }

  .add-gateway .gateway .main-section,
  .create-tag .create-tag-inner,
  .space-details .main-section .whole-section,
  .legal-compliance .legal-accordion,
  .gateway-detail .goback {
    padding: 20px;
  }

  .general .box {
    padding: 15px 13px;

    h2 {
      font-size: 14px;
    }
  }

  .Gateways .g-row {
    padding: 15px;
  }

  .Gateways .g-header {
    padding: 15px 10px;
  }

  .admin-body {
    overflow-y: scroll;
  }

  .auth-body .auth-form .signup-btns {
    width: 100%;
  }

  .search-bar .react-datalist-input__container input {
    width: 120px;
  }

  .cole-book-list-card.header {
    display: none;
  }

  .top-nav-section-outer {
    padding: 0px 20px !important;
  }

  .admin-body {
    padding: 20px 20px !important;
  }

  .cole-book-list-card {
    padding: 15px !important;
    // flex-wrap: wrap;
    flex-direction: column;
    position: relative;

    .book-detail {
      margin-bottom: 5px;
      width: 100% !important;
    }

    .book-detail:nth-child(1) {
      width: 20px !important;
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .book-detail:nth-child(2) {
      width: 100% !important;
    }

    .dropdown {
      width: 20px !important;
      position: absolute;
      bottom: 10px;
      right: 15px;
    }
  }

  .auth-header .right-nav .nav-link i {
    margin: 5px 10px 0px 0px;
    font-size: 18px;
  }

  .auth-header {
    padding: 0px 15px;

    .logo img {
      height: 30px;
    }
  }

  .model-btns-row {
    flex-wrap: wrap;
    justify-content: space-between;

    .btn {
      font-size: 12px;
      margin: 5px !important;
      width: calc(50% - 10px) !important;
    }
  }

  .body-content {

    h5,
    h6,
    h3 {
      margin-bottom: 15px !important;
    }
  }

  .dashboard-body {
    padding: 30px 15px;

    .body-content .add-btns {
      flex-direction: column;
      margin-bottom: 20px;

      .btn {
        margin-right: 0px;
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }

  .auth-body .auth-form .fav {
    height: 60px;
  }

  .header-row {
    width: 100%;
    display: flex;
    // justify-content: center;
  }

  .auth-body .auth-form {
    width: 100%;
    padding: 0px 20px;
  }

  .auth-body .auth-form small {
    font-size: 14px;
  }

  .auth-body .auth-form h2 {
    font-size: 24px;
  }

  .auth-body .auth-form h6 {
    font-size: 16px;
  }

  .auth-body h5 {
    font-size: 24px;
  }

  .auth-body .auth-form .field-row {
    width: 100%;
  }

  .auth-body .network-url {
    width: 100%;
  }

  .auth-body .devider {
    padding: 30px 0px 20px;
  }

  .auth-body .devider .xyz {
    width: 300px;
  }

  .auth-body .auth-form .mail-img {
    height: 80px;
  }

  .auth-body .auth-form h6 {
    font-size: 14px;
  }
}

// ----------Faizan Code----------------
#dashboardBodyOuter .dashboard-body .body-content .name {
  gap: 0px;
}

#dashboardBodyOuter .dashboard-body .body-content .add-btns {
  margin-bottom: 0px;
}

#dashboardBodyOuter .dashboard-body .body-content>div {
  margin-bottom: 40px;
  @media only screen and (max-width: 767px) {
    margin-bottom: 10px;
  }
}

#dashboardBodyOuter .dashboard-body .body-content .Content-wrapper .card {
  padding: 30px;
  border-radius: 9px;
  border: 1px solid var(--Borders-Neutral, #dee1e6);
  background: #fff;
}

#dashboardBodyOuter .dashboard-body .body-content .Content-wrapper .card button {
  font-size: 0.875rem;
}

#dashboardBodyOuter .dashboard-body .body-content .Content-wrapper .card img {
  width: 40px;
  height: 40px;
}

#dashboardBodyOuter .dashboard-body .body-content .Content-wrapper {
  margin: 60px 0;
  @media only screen and (max-width: 767px) {
    margin: 30px 0 ;
  }
}

#dashboardBodyOuter .dashboard-body .body-content .Content-wrapper .card .circle {
  width: 36px;
  height: 36px;
  border-radius: 100px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);

  img {
    width: 24px;
    height: 24px;
  }
}

.directoryModal .modal-content {
  border-radius: 12px;
  border: 1px solid var(--line, #dee1e6);
  background: #fff;

  /* Shadow */
  box-shadow: 0px 9px 19px 0px rgba(0, 0, 0, 0.1);

  .modal-header {
    padding: 12px 20px;
    border-bottom: 1px solid var(--line, #dee1e6);
    background: #fff;

    .modal-title {
      color: var(--Primary-text, #2d2f31);
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.02rem;
    }
  }

  .modal-body {
    padding: 25px 20px 0px 20px !important;
    max-height: 315px;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .filter-students {
      padding: 10px 10px 10px 0 !important;
      width: 100%;
      height: 40px;
      border-radius: 4px;
      border: 1px solid var(--line, #dee1e6);
      background: #fff;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      @media screen and (max-width:767px) {
        padding:7px 10px 7px 0 !important;
      }
      img, svg{
        @media screen and (max-width:767px) {
          width: 13px;
          height: 15px;
          object-fit: contain;      
        }
      }
      input {
        border: none;
        width: 100%;
        color: rgba(0, 0, 0, 0.7);
        @media screen and (max-width:767px) {
          font-size: 12px;
          line-height: 18px;
        }
        &::placeholder {
          color: var(--text-2, #a29e9e);
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          @media screen and (max-width:767px) {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }

    .student-header {
      height: 40px;

      p {
        color: var(--Primary-text, #2d2f31);
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
        @media only screen and (max-width: 767px) {
          font-size: 12px;
          line-height: 18px;
        }
        /* 171.429% */
      }
    }

    ul.list-unstyled {
      li {
        margin-bottom: 10px;

        .data-wrapper {
          height: 64px;
          border-radius: 8px;
          border: 1px solid var(--line, #dee1e6);
          background: #fff;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

          @media screen and (max-width:576px){
            height: auto;
            padding: 12px 0;
          .view-icon-wrapper{
          a{
            .maxi-icon{
              height: 18px;
              width: 18px;
            }
          }
        }
      }

      p{
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #202223;
      }
        }
      }
    }
  }

  .modal-footer {

    span {
      color: var(--Primary-text, #2d2f31);
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      
      @media screen and (max-width:576px) {
        font-size: 12px;
      line-height: 18px;
        }
    }

    select {
      border: none;
      color: var(--Primary-text, #2d2f31);
      text-align: center;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem;
      /* 142.857% */
      background: transparent;
      &:hover {
        border: none;
      }

      &:focus {
        border: none;
      }
    }
  }
}

// Highlight js Page Scss

.highlights-page {
  padding: 40px 0;

  h2 {
    color: var(--Primary-text, #2d2f31);
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.5rem;
    /* 125% */
    letter-spacing: -0.04rem;
    margin-bottom: 20px;
@media screen and (max-width:576px){
  font-size: 24px;
  line-height: 32px; 
}
  }

  .highligh-section {
    .card {
      border-radius: 10px;
      border: 1px solid var(--line, #dee1e6);
      background: #fff;

      /* Grid view card shadow */
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);

      .card-body {
        padding: 12px;

        .icon-bg {
          width: 36px;
          height: 36px;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
          @media screen and (max-width: 991px) and (min-width: 768px) {
            width: 30px;
            height: 30px;
            margin: 0 10px 0 0 !important;
            img, svg{
              width: 18px;
              height: 18px;
            }
          }
        }
        .arrow-icon{
          img, svg{
            @media screen and (max-width: 991px) and (min-width: 768px) {
              width: 16px;
              height: 16px;
            }
          }
        }
        .name {
          color: var(--text-icons-primary, #2d2f31);
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          @media screen and (max-width: 991px) and (min-width: 768px) {
            font-size: 14px;
            line-height: 22px;
          }
        }

        .number {
          color: var(--text-icons-primary, #2d2f31);
          text-align: center;
          font-family: Poppins;
          font-size: 2.5rem;
          font-style: normal;
          font-weight: 600;
          line-height: 3rem;
          /* 120% */
        }
      }
    }
  }

  .student-activity-card {
    border-radius: 10px;
    border: 1px solid var(--line, #dee1e6);
    background: #fff;

    /* Grid view card shadow */
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
    margin-bottom: 20px;

    .card-header {
      border-radius: 10px 10px 0px 0px;
      border-bottom: 1px solid var(--Borders-Neutral, #dee1e6);
      background: #fff;

      .heading {
        color: var(--Primary-text, #2d2f31);

        /* Heading 3/Semibold */
        font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.75rem;
        /* 140% */
      }

      .dropdown {
        border-radius: 4px;
        background: #fff;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

        #dropdown-basic {
          padding: 10px 12px;
          color: var(--Primary-text, #2d2f31);
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .card-body {
      padding: 20px;
      height: 320px;


    }
  }

  .assesment {
    margin-bottom: 40px;

    .custom-card {
      border-radius: 10px;
      border: 1px solid var(--line, #dee1e6);
      background: #fff;

      /* Grid view card shadow */
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
      height: 160px;

      .title {
        color: var(--text-icons-primary, #2d2f31);
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .icon {
        width: 36px;
        height: 36px;
        border-radius: 100px;
        background: #fff;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);

        img {
          width: 24px;
        }
      }

      .count {
        color: var(--text-icons-primary, #2d2f31);
        font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 1.5rem */
      }

      .bar-data {
        color: var(--text-icons-primary, #2d2f31);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .last-section {
    .leader-board {
      border-radius: 10px;
      border: 1px solid var(--line, #dee1e6);
      background: #fff;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
      padding: 20px;
      max-height: 600px;
      overflow-y: auto;
      scrollbar-width: none;
      /* Firefox */
      -ms-overflow-style: none;
      /* Internet Explorer 10+ */

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        display: none;
        /* Chrome, Safari, Opera */
      }

      .header {
        padding-bottom: 14px;
        border-bottom: 1px solid var(--line, #dee1e6);
        margin-bottom: 10px;

        .leader-board-title {
          color: var(--Primary-text, #2d2f31);
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.025rem;
        }
      }

      .student-data-title {
        margin-bottom: 10px;

        span {
          color: var(--Primary-text, #2d2f31);
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.5rem;
          /* 171.429% */
        }
      }

      .filter-students {
        padding: 10px 0 !important;
        width: 100%;
        height: 40px;
        border-radius: 4px;
        border: 1px solid var(--line, #dee1e6);
        background: #fff;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;

        input {
          border: none;
          width: 100%;
          color: rgba(0, 0, 0, 0.7);

          &::placeholder {
            color: var(--text-2, #a29e9e);
            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .leaderboard-table{

        overflow-x: auto;
      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 10px;
      
        thead {
          tr {

            th:nth-child(3){
              text-wrap: nowrap;
            }
      
            th {
              padding: 10px;
              text-align: left;
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
              color: #202223;
            }
          }
        }
      
        tbody {
          tr {
            background-color: #fff;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
            border-radius: 8px;

            td:nth-child(2){
              min-width: 170px;
            }
      
            td {
              padding: 13px 10px;
              // vertical-align: middle;
              border-top: 1px solid #dee1e6;
              border-bottom: 1px solid #dee1e6;

              .rank-number-wrapper{
                height: 36px;
                width: 36px;
                display: flex;
                align-items: center;
                border-radius: 50%;
                justify-content: center;
      box-shadow:0px 1px 3px 0px rgba(0, 0, 0, 0.25);

      .student_img_wrapper{
        font-size: 16px;
                line-height: 21px;
                font-weight: 400;
                color:#202223;
      }
              }

              .img-name-wrapper{
                display: flex;
                white-space: nowrap;
                align-items: center;
              }
      
              img {
                height: 36px;
                width: 36px;
                object-fit: cover;
                border-radius: 50%;
              }
      
              &:first-child {
                border-left: 1px solid #dee1e6;
                border-top-left-radius: 8px; 
                border-bottom-left-radius: 8px;
              }

              .time-col-text,
                .coin-col-text{
                margin: 0;
                color:#202223;
                font-size: 18px;
                line-height: 26px;
                font-weight: 400;
                text-wrap: nowrap;

                @media screen and (max-width:576px) {
                  font-size: 14px;
        line-height: 21px;
                  }
              }

              .name{
                font-size: 20px;
                line-height: 28px;
                font-weight: 500;
                color:#202223;

                @media screen and (max-width:576px) {
                font-size: 16px;
                line-height: 24px;
                }
              }
      
              &:last-child {
                border-right: 1px solid #dee1e6;
                border-top-right-radius: 8px; 
                border-bottom-right-radius: 8px; 
              }
            }
          }
        }
      }
    }
  }

    .trophies {
      border-radius: 10px;
      border: 1px solid var(--line, #dee1e6);
      background: #fff;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
      padding: 18px;
      max-height: 600px;
      overflow-y: auto;
      scrollbar-width: none;
      /* Firefox */
      -ms-overflow-style: none;
      /* Internet Explorer 10+ */

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        display: none;
        /* Chrome, Safari, Opera */
      }

      .header {
        gap: 20px;
        margin-bottom: 18px;
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          gap: 15px;
        }
        .img-container {
          width: 100px;
          height: 100px;
          border-radius: 79px;
          border: 1px solid var(--Borders-Neutral, #dee1e6);
          background: #fff;
          @media screen and (max-width: 1199px) and (min-width: 992px) {
            width: 65px;
            height: 65px;
          }
          @media screen and (max-width:576px) {
            width: 60px;
            height: 60px;
          }

          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);

          img {
            height: 60px;
            width: 60px;
            object-fit: contain;
            @media screen and (max-width: 1199px) and (min-width: 992px) {
              height: 30px;
              width: 30px;
            }
            @media screen and (max-width:576px) {
              height: 36px;
              width: 36px;
            }
          }
        }

        .header-text-container {
          .title {
            color: var(--Primary-text, #2d2f31);

            /* Heading1/Semibold */
            font-family: Poppins;
            font-size: 34px;
            font-style: normal;
            font-weight: 600;
            line-height: 40px;
            @media screen and (max-width: 1199px) and (min-width: 992px) {
              font-size: 20px;
              line-height: 28px;
            }
            @media screen and (max-width:576px) {
              font-size: 24px;
              line-height: 32px;
            }
          }
        }

        img {
          width: 24px;
          height: 24px;
          @media screen and (max-width: 1199px) and (min-width: 992px) {
            width: 16px;
            height: 16px;
            margin: 0 2px 0 0;
          }
          @media screen and (max-width:576px) {
            width: 22px;
            height: 22px;
          }
        }

        span {
          color: var(--On-Surface-Text-Colour, #202223);
          font-family: Poppins;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5rem;
          @media screen and (max-width: 1199px) and (min-width: 992px) {
            font-size: 14px;
            line-height: 22px;
          }
          /* 120% */
          @media screen and (max-width:576px) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }

    .trophies-list-header div {
      display: flex;
      height: 40px;
      padding: 8px 20px 8px 13px;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      align-self: stretch;
      color: var(--Primary-text, #2d2f31);
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
      /* 171.429% */
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          font-size: 14px;
          line-height: 22px;
        }
    }

    .trophies-card {
      border-radius: 8px;
      border: 1px solid var(--line, #dee1e6);
      background: #fff;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

      .card-body {
        padding: 14px 16px;

        .subject{
          font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: #202223;

    @media screen and (max-width:576px){
      font-size: 16px;
        line-height: 24px;
    }
        }

        .firstimg {
          width: 36px;
          height: auto;
          border-radius: 50%;
          border: 1px solid #dee1e6;
          background: #fff;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
        }

        .sunject {
          color: var(--On-Surface-Text-Colour, #202223);
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.5rem;
          /* 171.429% */
        }

        .number {
          color: var(--On-Surface-Text-Colour, #202223);
          font-family: Poppins;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5rem;

          @media screen and (max-width:576px) {
            font-size: 16px;
            line-height: 24px;
          }

        }

        .img-wrapper {
          width: 36px;
          height: 36px;
          border-radius: 69px;
          background: #fff;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);


          @media screen and (max-width:576px) {
            min-width: 32px;
        width: 32px;
        height: 32px;
          }


          img {
            width: 24px;
            height: 24px;

            @media screen and (max-width:576px) {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}