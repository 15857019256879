.search-assets {
    // margin-block: 35px;
    position: relative;

    svg {
        position: absolute;
        left: 18px;
        top: 12px;
    }

    input {
        padding-left: 50px;
        min-height: 40px !important;
        width: 100%;
        border-radius: 4px !important;
        border: 1px solid var(--line, #dee1e6) !important;
        background: var(--White, #fff);
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1) !important;
    }

    input::placeholder {
        color: var(--text-2, #a29e9e);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}